import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateRangeI} from '../../../app-interfaces/date-range-i';
import {FilterItem} from '../../../app-models/filter-item.model';
import {AbstractFilterComponent} from "../abstract-filter.component";
import {DateConverter} from "../../../utils/date-converter";
import {CommonFilterComponent} from "../common-filter/common-filter.component";
import {DayjsUtil} from "../../../dayjs.util";
import {LocaleConfig} from "ngx-daterangepicker-material-no-utc";

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss']
})

export class DateRangeFilterComponent extends AbstractFilterComponent<DateRangeI> implements OnInit {

  readonly TIME_FORMAT = 'HH:mm';
  readonly DAY_TIME_FORMAT = 'DD.MM ' + this.TIME_FORMAT;

  @ViewChild(CommonFilterComponent) commonFilterComponent: CommonFilterComponent;

  @Input() dateRangeLimits: DateRangeI;
  @Input() itemList: FilterItem[] = [];
  @Input() showRange = false;
  @Input() timeZone: string;

  readonly LOCALE: LocaleConfig = {
    customRangeLabel: ' - ',
    applyLabel: this.translateService.instant('action.apply'),
    daysOfWeek: DayjsUtil.weekdaysMin(),
    monthNames: DayjsUtil.monthsShort(),
  };

  constructor(private translateService: TranslateService) {
    super();
  }

  public onOpen(): void {
  }

  public onClear(): void {
    this.viewValue = null;
    this.filterChange.emit(null);
  }

  onChange(timeRange: Object) {
    let result: DateRangeI;
    if (!timeRange || !timeRange['startDate'] || !timeRange['endDate']) {
      this.viewValue = null;
    } else {
      result = {
        startDate: timeRange['startDate'],
        endDate: timeRange['endDate']
      }
      this.viewValue = this.toString(result);
    }
    this.filterChange.emit(result);
    this.commonFilterComponent.close();
  }

  private toString(timeRange: DateRangeI) {
    if (!timeRange) {
      return undefined;
    }
    let endTimeFormat = this.DAY_TIME_FORMAT;
    if (this.isSameDay(timeRange)) {
      endTimeFormat = this.TIME_FORMAT;
    }
    const startTime = DateConverter.utcDateTimeToTzDateString(timeRange['startDate'].toISOString(), this.timeZone, this.DAY_TIME_FORMAT);
    const endTime = DateConverter.utcDateTimeToTzDateString(timeRange['endDate'].toISOString(), this.timeZone, endTimeFormat);
    return `${startTime} - ${endTime}`;
  }

  isSameDay(dateRange: DateRangeI): boolean {
    return dateRange ? dateRange.startDate.isSame(dateRange.endDate, 'day') : false;
  }

  ngOnInit(): void {
    this.viewValue = this.toString(this.filter);
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-dialog-save',
  templateUrl: './dialog-save.component.html',
  styleUrls: ['./dialog-save.component.scss']
})
export class DialogSaveComponent {
  @Input() title = 'Example Title';
  @Input() disableSave = false;
  @Input() showSpinner = false;
  @Input() saveButtonShowSpinner = false;

  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

}

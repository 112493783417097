import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

import {
  BaseUnsubscribeComponent
} from "../../../../../user-portal/src/app/shared/components/base-unsubscribe.component";
import {takeUntil} from "rxjs/operators";
import {LocalDate} from "@js-joda/core";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent extends BaseUnsubscribeComponent implements OnInit {

  control: FormControl<Date>;

  @Output() valid = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<Date>();

  ngOnInit(): void {
    this.control = new FormControl(this.getTomorrowDate());
    this.valueChange.emit(this.getTomorrowDate());
    this.control.statusChanges.pipe(takeUntil(this.destroy))
      .subscribe(status => this.valid.emit(status === 'VALID'))
    this.control.valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(value => this.valueChange.emit(value))
  }

  getTomorrowDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  }
}

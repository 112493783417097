import {Component} from '@angular/core';
import {StyledAbstractIconButtonComponent} from "../../core/icon-styled-button/styled-abstract-icon-button.component";

@Component({
  selector: 'app-icon-button-red',
  templateUrl: './icon-button-red.component.html',
})
export class IconButtonRedComponent extends StyledAbstractIconButtonComponent {

}

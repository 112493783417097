import {DeviceBrand} from './device-brand.model';
import {DeviceProtocol} from './device-protocol.model';

export class DeviceModel {

  constructor(public readonly id: number,
              public name: string,
              public brand: DeviceBrand,
              public protocols: DeviceProtocol[]) {
  }

  public static valueOf(o: DeviceModel): DeviceModel {
    return o ? new DeviceModel(o.id, o.name, DeviceBrand.valueOf(o.brand), DeviceProtocol.valuesOf(o.protocols)) : null;
  }

  public static valuesOf(list: DeviceModel[]): DeviceModel[] {
    return list ? list.map(o => DeviceModel.valueOf(o)) : [];
  }

  public static distributeModelsByBrands(models: DeviceModel[]): Map<string, DeviceModel[]> {
    if (!models || models?.length === 0) {
      return new Map();
    }
    const modelBrandMap = new Map();
    models.forEach(model => {
      if (!modelBrandMap.has(model.brand.name)) {
        modelBrandMap.set(model.brand.name, []);
      }
      modelBrandMap.set(model.brand.name, modelBrandMap.get(model.brand.name).concat(model));
    });
    return modelBrandMap;
  }

  public static distributeModelNamesByBrands(models: DeviceModel[]): Map<string, string[]> {
    const modelsByBrands: Map<string, string[]> = new Map();
    models.forEach(model => {
      if (modelsByBrands.has(model.brand.name)) {
        modelsByBrands.set(model.brand.name, modelsByBrands.get(model.brand.name).concat(model.name));
      } else {
        modelsByBrands.set(model.brand.name, [model.name]);
      }
    });
    return modelsByBrands;
  }
}

import {Component, Directive, EventEmitter, Input, Output} from '@angular/core';
import {
  ActionAbstractButtonComponent
} from "../../../common/concrete-button/action-abstract-button.component";
import selector from "echarts/types/src/component/brush/selector";

@Directive({
  selector: 'ignore-selector',
})
export class StyledAbstractIconButtonComponent extends ActionAbstractButtonComponent {
  @Input() icon = 'local_bar'

  @Input() toggle: 'primary' | 'accent' = 'primary'

  @Output() action = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.action.emit(event);
  }
}

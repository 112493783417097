import {Pipe, PipeTransform} from '@angular/core';
import {DayjsUtil} from "../dayjs.util";



@Pipe({
  name: 'utcDateTz'
})

export class TimestampTimezoneConvertPipe implements PipeTransform {

  transform(datetime: string, tzName: string, format: string = 'DD.MM.YYYY HH:mm:ss'): string {
    if (!DayjsUtil.instant(datetime).isValid()) {
      return '';
    }
    return DayjsUtil.utc(datetime).tz(tzName)?.format(format);
  }
}

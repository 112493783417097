import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {startWith} from "rxjs/operators";
import {LanguageService} from "../../services/language.service";
import {Observable} from "rxjs";
import {Language} from "../../modelinterfaces/language.model";
import {UntypedFormControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {MatFormFieldAppearance} from "@angular/material/form-field";

/**
 * Component responsible for selecting a language.
 *
 * This component interacts with `LanguageService` to retrieve available languages
 * and with `ViewLanguageService` to get and set the current language.
 *
 * It provides a dropdown menu for users to select their preferred language,
 * and emits an event when the selection is changed.
 * @see ChangeLanguageComponent
 */
@Component({
  selector: 'cmn-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit {

  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'fill'
  @Input() language: string;

  /**
   * Fire event when select change contains language name
   * @example 'ru', 'be'
   */
  @Output() languageChange = new EventEmitter<string>();

  $languages: Observable<Language[]>;
  /**
   * The current selected language.
   */
  current: string;
  control = new UntypedFormControl();

  constructor(private languageService: LanguageService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.$languages = this.languageService.getList().pipe(startWith([]));
    this.control.setValue(this.language);
    if(!this.label) {
      this.label = this.translateService.instant('term.language-interface')
    }
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cmn-button-base',
  templateUrl: './button-base.component.html',
  styleUrls: ['./button-base.component.scss']
})
export class ButtonBaseComponent {
  @Input() styleClass: string;
  @Input() text = 'Example text'
  @Input() disabled = false;
  @Input() showSpinner = false;

  @Output() action = new EventEmitter<MouseEvent>();
}

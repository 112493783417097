import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomizationChartI } from '../../app-interfaces/customization-chart-i';
import { Sensor } from '../../modelinterfaces/sensor.model';


export interface ChartSettingsDialogDataI {
  sensors: Sensor[];
  customization: CustomizationChartI[];
}

enum Controls {
  ID = 'id',
  DISABLED_SENSOR_NAME = 'disabled-sensor-name',
  SENSOR_NAME = 'sensor-name',
  FROM = 'from',
  TO = 'to'
}

@Component({
  selector: 'app-chart-settings-dialog',
  templateUrl: './chart-settings-dialog.component.html',
  styleUrls: ['./chart-settings-dialog.component.scss', '../../app-styles/dialog-common.scss']
})

export class ChartSettingsDialogComponent implements OnInit {

  public readonly FORM_ARRAY_NAME = 'array';

  public controls = Controls;
  public formHighlightArea: UntypedFormGroup;

  private currentId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChartSettingsDialogDataI,
              private dialogRef: MatDialogRef<ChartSettingsDialogComponent>,
              private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.currentId = this.getCurrentId();
    this.buildForm();
  }

  get formArray(): UntypedFormArray {
    return this.formHighlightArea.get(this.FORM_ARRAY_NAME) as UntypedFormArray;
  }

  private getCurrentId(): number {
    const idList: number[] = this.data.customization.map(el => el.id);
    return Math.max(0, ...idList) + 1;
  }

  private buildForm(): void {
    const formGroupList = [];
    this.data.customization.forEach(el => {
      formGroupList.push(this.createFormGroup(el));
    });
    this.formHighlightArea = this.formBuilder.group({
      [this.FORM_ARRAY_NAME]: this.formBuilder.array(formGroupList)
    });
  }

  private createFormGroup(customization: CustomizationChartI = null): UntypedFormGroup {
    let isDisabled = false;
    if (customization?.sensorNameTranslated) {
      isDisabled = !this.data.sensors.some(el => el.nameTranslated === customization.sensorNameTranslated);
    }
    return this.formBuilder.group({
      [Controls.ID]: new UntypedFormControl(
        customization?.id ? customization.id : this.currentId
      ),
      [Controls.SENSOR_NAME]: new UntypedFormControl(customization?.sensorNameTranslated ? customization?.sensorNameTranslated : null,
        Validators.required
      ),
      [Controls.DISABLED_SENSOR_NAME]: new UntypedFormControl(
        customization?.sensorNameTranslated ? customization.sensorNameTranslated : null
      ),
      [Controls.FROM]: new UntypedFormControl(
        {value: this.isExistValue(customization?.from) ? customization.from : null, disabled: isDisabled}
      ),
      [Controls.TO]: new UntypedFormControl(
        {value: this.isExistValue(customization?.to) ? customization.to : null, disabled: isDisabled}
      )
    });
  }

  private isExistValue(value: number): boolean {
    if (value === Number.MIN_SAFE_INTEGER || value === Number.MAX_SAFE_INTEGER) {
      return false;
    }
    if (value === 0) {
      return true;
    }
    return Boolean(value);
  }

  public onAdd(): void {
    this.formArray.push(this.createFormGroup());
    this.currentId += 1;
  }

  public onDelete(index: number): void {
    this.formArray.removeAt(index);
    this.formHighlightArea.updateValueAndValidity();
    this.formHighlightArea.markAsDirty();
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    const customization: CustomizationChartI[] = [];
    for (let i = 0; i < this.formArray.controls.length; i++) {
      const customizationChart: CustomizationChartI = {
        id: this.formArray.controls[i].get(Controls.ID).value,
        sensorNameTranslated: this.formArray.controls[i].get(Controls.SENSOR_NAME).value,
        from: this.transformValue(this.formArray.controls[i].get(Controls.FROM).value, Number.MIN_SAFE_INTEGER),
        to: this.transformValue(this.formArray.controls[i].get(Controls.TO).value, Number.MAX_SAFE_INTEGER),
      };
      customization.push(customizationChart);
    }
    this.dialogRef.close({customization: customization});
  }

  private transformValue(value: number, limit: number): number {
    if (value === 0) {
      return value;
    }
    if (!value) {
      return limit;
    }
    return value;
  }
}

import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  AbstractControlValueAccessor
} from "../../../../../../../user-portal/src/app/system/logistic/common/components/inputs/abstract-input/abstract-control-value.accessor";
import {NumberRangeFilter} from "../number-range-filter.component";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";

enum Control {
  START = 'START',
  END = 'END'
}

@Component({
  selector: 'cmn-filter-number-range-input',
  templateUrl: './filter-number-range-input.component.html',
  styleUrl: './filter-number-range-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterNumberRangeInputComponent),
      multi: true
    }
  ]
})
export class FilterNumberRangeInputComponent extends AbstractControlValueAccessor<NumberRangeFilter> implements OnInit {

  controls = Control;
  @Input() label: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() step = 1;
  @Input() uom = '';

  @ViewChild('startInput') startInputRef: ElementRef;
  @ViewChild('endInput') endInputRef: ElementRef;



  constructor() {
    super(
      new UntypedFormGroup({
        [Control.START]: new UntypedFormControl(null),
        [Control.END]: new UntypedFormControl(null)
      }, FilterNumberRangeInputComponent.rangeValidator())
    );
  }

  ngOnInit(): void {
    this.setValidators();
  }

  private setValidators() {
    let validators = this.minMaxValidators();
    this.form.controls[Control.START].setValidators(validators);
    this.form.controls[Control.END].setValidators(validators);
    this.form.updateValueAndValidity();
  }

  private minMaxValidators(): ValidatorFn[] {
    return [
      this.minValue !== undefined ? Validators.min(this.minValue) : null,
      this.maxValue !== undefined ? Validators.max(this.maxValue) : null,
    ].filter(validator => validator !== null) as ValidatorFn[];
  }

  private static rangeValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const start = group.controls[Control.START].value;
      const end = group.controls[Control.END].value;
      if (start !== null && end !== null && start > end) {
        return {rangeInvalid: true};
      }
      return null;
    };
  }

  protected convertFormToValue(): NumberRangeFilter {
    if (this.form.valid) {
      return {
        start: this.form.value[Control.START],
        end: this.form.value[Control.END]
      }
    } else {
      return null;
    }
  }

  protected convertValueToForm(value: NumberRangeFilter): { [p: string]: any } {
    return {[Control.START]: value?.start ?? null, [Control.END]: value?.end ?? null};
  }

  onKey($event: KeyboardEvent) {
    if ($event.key === 'Tab') {
      $event.stopPropagation();
      setTimeout(() => {
        this.endInputRef.nativeElement.focus();
      }, 0);
    }
  }

  onBackspace($event: any, startInput: HTMLInputElement, endInput: HTMLInputElement) {
    if (endInput.value === '') {
      startInput.focus();
    }
  }

  public focus() {
    this.startInputRef.nativeElement.focus();
  }

}

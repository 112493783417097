import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-apply',
  templateUrl: './button-apply.component.html'
})
export class ButtonApplyComponent extends ActionAbstractButtonComponent {
  @Output() apply = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.apply.emit(event);
  }
}

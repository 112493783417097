import {Injector, LOCALE_ID} from '@angular/core';
import {TranslateLoader, TranslateService} from '@ngx-translate/core';
import {from, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {environment} from '../../../environments/environment';

export function initTranslationService(translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get<any>(LOCALE_ID, Promise.resolve(null));

    let defaultLang = window.localStorage.getItem(LocalStorageKey.LANGUAGE);
    defaultLang = defaultLang ? defaultLang : environment.DEFAULT_LANGUAGE;
    translate.setDefaultLang(defaultLang);
    try {
      await translate.use(defaultLang).toPromise();
    } catch (err) {
      console.log(err);
    }
  };
}

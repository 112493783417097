import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-build',
  templateUrl: './button-build.component.html',
})
export class ButtonBuildComponent extends ActionAbstractButtonComponent {
@Output() build = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.build.emit(event);
  }
}

export class AppVersion {

  constructor(public appVersion: string,
              public metrVersion: string,
              public metrChecksum: string) {
  }

  public static valueOf(o: AppVersion): AppVersion {
    return o ? new AppVersion(o.appVersion, o.metrVersion, o.metrChecksum) : null;
  }
}

import {DateConverter} from './date-converter';
import {TranslateService} from '@ngx-translate/core';

export class DurationConverter {

  public static secondsToStringDuration(secondsDuration: number, translateService: TranslateService): string {
    let hours: number = Math.floor(secondsDuration / 3600);
    const minutes: number = Math.floor((secondsDuration - hours * 3600) / 60);
    const seconds: number = secondsDuration - (hours * 3600 + minutes * 60);

    const d = translateService.instant('time.d');
    const h = translateService.instant('time.h');
    const min = translateService.instant('time.min');
    const sec = translateService.instant('time.sec');

    if (hours > 24) {
      const days = Math.floor(hours / 24);
      hours = hours - days * 24;
      return `${days} ${d} ${this.toString(hours)} ${h} ${this.toString(minutes)} ${min}`;
    }
    if (hours >= 1) {
      return `${this.toString(hours)} ${h} ${this.toString(minutes)} ${min}`;
    }
    if (minutes > 0) {
      return `${this.toString(minutes)} ${min} ${this.toString(seconds)} ${sec}`;
    } else {
      return `${this.toString(seconds)} ${sec}`;
    }
  }

  public static stringDatetimeToStringDuration(d: string[], translateService: TranslateService): string {
    const seconds = this.stringDatetimeToSecondsDuration(d);
    if (!seconds) {
      return '-';
    }
    return this.secondsToStringDuration(seconds, translateService);
  }

  public static stringDatetimeToSecondsDuration(d: string[]): number {
    const s = DateConverter.utcDatetimeToTzDate(d[0], 'UTC');
    const f = DateConverter.utcDatetimeToTzDate(d[1], 'UTC');
    const timeDifference = DurationConverter.milliSecondsToSeconds(f) && DurationConverter.milliSecondsToSeconds(s) ?
      DurationConverter.milliSecondsToSeconds(f) - DurationConverter.milliSecondsToSeconds(s) : 0;
    return Math.abs(timeDifference);
  }

  private static milliSecondsToSeconds(date: Date): number {
    return date ? date.getTime() / 1000 : 0;
  }

  private static toString(value: number): string {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  }

  static secondsToHours(seconds: number, scale: number): string {
    return seconds.toFixed(scale);
  }

  public static secondsToHourString(seconds: number): string {
    const h = DurationConverter.toString(Math.trunc(seconds / 3600));
    const m = DurationConverter.toString(Math.trunc(((seconds / 3600) % 1) * 60));
    return `${h}:${m}`;
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-close',
  templateUrl: './button-close.component.html',

})
export class ButtonCloseComponent extends ActionAbstractButtonComponent {
  @Input() type: 'minor' | 'primary' = 'minor';
  @Output() close = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.close.emit(event);
  }
}

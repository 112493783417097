import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimeZone } from '../modelinterfaces/time-zone.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class TimeZoneService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(): Observable<TimeZone[]> {
    const url = `${this.environmentHelper.urlV2}/timezone`;
    return this.http.get<TimeZone[]>(url).pipe(
      map(list => TimeZone.valuesOf(list))
    );
  }
}

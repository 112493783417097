import {Component} from '@angular/core';
import {StyledAbstractButtonComponent} from "../../core/styled-abstract-button/styled-abstract-button.component";

@Component({
  selector: 'app-button-primary',
  templateUrl: './button-primary.component.html'
})
export class ButtonPrimaryComponent extends StyledAbstractButtonComponent {

}

import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-add',
  templateUrl: './button-add.component.html',
})
export class ButtonAddComponent extends ActionAbstractButtonComponent {
@Output() add = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.add.emit(event);
  }
}

export class Customization {

  constructor(public dealerId: number,
              public domainUser: string,
              public domainAdmin: string,
              public faviconUrl: string,
              public faviconText: string,
              public logoUrl: string,
              public theme: string,
              public title: string,
              public deviceServer: string) {
  }

  public static valueOf(obj: Customization): Customization {
    return obj ? new Customization(obj.dealerId, obj.domainUser, obj.domainAdmin, obj.faviconUrl, obj.faviconText, obj.logoUrl, obj.theme, obj.title, obj.deviceServer) : null;
  }
}

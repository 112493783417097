import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {startWith, map, distinctUntilChanged, shareReplay} from 'rxjs/operators';

const QUERY: Map<string, string> = new Map([
  ['xl', '(min-width: 1140px)'],
  ['l', '(min-width: 960px)'],
  ['m', '(min-width: 720px)'],
  ['s', '(min-width: 0px)'],
]);

export class ViewSize {

  private readonly min: number;
  private readonly max: number;
  private readonly size: string;

  constructor(min: number, max: number, size: 's'|'m'|'l'|'xl') {
    this.min = min;
    this.max = max;
    this.size = size;
  }

  public more(size: 's'|'m'|'l'|'xl'): boolean {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const that = ViewBreakpointService.SIZES.get(size);
    return that.min < this.min;
  }

  public less(size: 's'|'m'|'l'|'xl'): boolean {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const that = ViewBreakpointService.SIZES.get(size);
    return that.max > this.max;
  }

  public equal(size: 's'|'m'|'l'|'xl'): boolean {
    return size === this.size;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ViewBreakpointService {

  public static readonly SIZES: Map<string, ViewSize> = new Map([
    ['xl', new ViewSize(1140, 100000, 'xl')],
    ['l', new ViewSize(960, 1139, 'l')],
    ['m', new ViewSize(720, 959, 'm')],
    ['s', new ViewSize(0, 719, 's')],
  ]);

  private readonly _size$: Observable<ViewSize>;
  private _currentSize: ViewSize;

  constructor() {
    this._size$ = fromEvent(window, 'resize')
      .pipe(
        startWith(this._getScreenSize()),
        map(() => {
          this._currentSize = this._getScreenSize();
          return this._currentSize;
        }),
        distinctUntilChanged(),
        shareReplay(1)
      );
  }

  private _getScreenSize(): ViewSize {
    const [[newSize = 'never']] = Array.from(QUERY.entries())
      .filter(([size, mediaQuery]) => window.matchMedia(mediaQuery).matches);
    return ViewBreakpointService.SIZES.get(newSize);
  }

  get size$(): Observable<ViewSize> {
    return this._size$;
  }

  get currentSize(): ViewSize {
    return this._currentSize;
  }
}

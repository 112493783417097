import { Injectable, NgZone } from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {SnackBarComponent} from '../app-components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})

export class AppMessageService {

  constructor(private snackBar: MatSnackBar,
              private translateService: TranslateService,
              private zone: NgZone) {
  }

  public openSnackBar(message: string,
                      isError: boolean = false,
                      params: object = null,
                      duration: number = 3000,
                      additions: string = ''): void {
    if (!message) {
      return;
    }
    const v = this.translateService.instant(message, params);
    const config = this.createConfig(isError, duration);
    this.zone.run(() => {
      this.snackBar.open(v.charAt(0).toUpperCase() + v.slice(1) + ' ' + additions, 'Ok', config);
    });
  }

  private createConfig(isError: boolean = false, duration: number = 3000): MatSnackBarConfig {
    const config: MatSnackBarConfig = {
      duration: duration
    };
    if (isError) {
      config.panelClass = ['error-message-snack-bar'];
    }
    return config;
  }

  public openSnackWithLink(message: string,
                           link: string,
                           isError: boolean = false,
                           duration: number = 10000): void {
    if (!message) {
      return;
    }
    const config = this.createConfig(isError, duration);
    this.snackBar.openFromComponent(SnackBarComponent,
      {
        data: {
          message: this.translateService.instant(message),
          link: link
        },
        ...config
      });
  }

  public copyToClipBoard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // make the textarea out of viewport
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy') ? this.openSnackBar('message.info.copied', false) :
      this.openSnackBar('message.error.error-while-saving', true);
    textArea.remove();
  }
}

import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FilterItem} from '../../../app-models/filter-item.model';
import {AbstractFilterComponent} from "../abstract-filter.component";
import {comparisonId as comparisonIdUtility} from "../../../utils/comparison-function.util";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-checkbox-list-filter',
  templateUrl: './checkbox-list-filter.component.html',
  styleUrls: ['./checkbox-list-filter.component.scss']
})

export class CheckboxListFilterComponent extends AbstractFilterComponent<number[]> implements OnInit {

  @Input() enableSearch = true;
  @Input() itemList: FilterItem[] = [];
  @Input() showSelectedNames = false;

  /**
   * Custom template for displaying each item. If not provided, item.name is used.
   * @example of usage
   * <app-checkbox-list-filter [name]="'Приоритет'"
   *                           [itemList]="priorities"
   *                           (filterChange)="onFilterChange($event)"
   *                           [itemTemplate]="customItemTemplate">
   *
   *   <ng-template #customItemTemplate let-item>
   *     <logistic-item-priority [priority]="item.name"></logistic-item-priority>
   *   </ng-template>
   *
   * </app-checkbox-list-filter>
   */
  @Input() itemTemplate: TemplateRef<any>

  public isMultipleSelection = true;

  public searchIn = '';
  public selection = new SelectionModel<FilterItem>(this.isMultipleSelection, []);

  private itemMap = new Map<number, FilterItem>();

  ngOnInit() {
    this.itemList.forEach(item => {
      this.itemMap.set(item.id, item);
    });
    if (this.filter && this.filter.length > 0) {
      const filterSet = new Set(this.filter);
      this.selection.select(...this.itemList.filter(item => filterSet.has(item.id)));
      this.viewValue = this.toViewValue(this.filter);
    }
  }

  public onToggleAllChange($event: any): void {
    $event.stopPropagation();
    if (this.selection.selected.length === this.itemList.length) {
      this.selection.clear();
    } else {
      this.selection.select(...this.itemList);
    }
  }

  public onClear(): void {
    this.selection.clear();
    this.onApply();
  }

  public onApply(): void {
    this.filter = this.selection.selected.map(item => item.id);
    this.filterChange.emit(this.filter);
    this.viewValue = this.toViewValue(this.filter);
  }

  private toViewValue(ids: number[]): string {
    if (this.showSelectedNames) {
      return ids.map(id => this.itemMap.get(id)?.translate || this.itemMap.get(id).name).join(", ");
    } else {
      return ids.length > 0 ? `${ids.length}` : null;
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceBrand } from '../modelinterfaces/device-brand.model';
import { DeviceModel } from '../modelinterfaces/device-model.model';
import { DeviceProtocol } from '../modelinterfaces/device-protocol.model';
import { DeviceBrandService } from '../services/device-brand.service';
import { DeviceModelService } from '../services/device-model.service';
import { DeviceProtocolService } from '../services/device-protocol.service';

@Injectable({
  providedIn: 'root'
})

export class DeviceService {

  private brandSource = new BehaviorSubject<DeviceBrand[]>(null);
  private _brands$ = this.brandSource.asObservable();

  private modelSource = new BehaviorSubject<DeviceModel[]>(null);
  private _models$ = this.modelSource.asObservable();

  private protocolSource = new BehaviorSubject<DeviceProtocol[]>(null);
  private _protocols$ = this.protocolSource.asObservable();

  constructor(private deviceBrandService: DeviceBrandService,
              private deviceModelService: DeviceModelService,
              private deviceProtocolService: DeviceProtocolService) {
  }

  get brands$(): Observable<DeviceBrand[]> {
    return this._brands$;
  }

  get models$(): Observable<DeviceModel[]> {
    return this._models$;
  }

  get protocols$(): Observable<DeviceProtocol[]> {
    return this._protocols$;
  }

  public initLoadBrands(): void {
    this.deviceBrandService.getList().subscribe(list => {
        this.brandSource.next(list);
      }
    );
  }

  public initLoadModels(userId: number): void {
    if (userId) {
      this.deviceModelService.getListByUser(userId).subscribe(list => {
          this.modelSource.next(list);
        }
      );
      return;
    }

    this.deviceModelService.getList().subscribe(list => {
        this.modelSource.next(list);
      }
    );
  }

  public initLoadProtocols(): void {
    this.deviceProtocolService.getList().subscribe(list => {
        this.protocolSource.next(list);
      }
    );
  }

  public instantBrands(): DeviceBrand[] {
    return this.brandSource.getValue();
  }

  public instantModels(): DeviceModel[] {
    return this.modelSource.getValue();
  }

  public instantProtocols(): DeviceProtocol[] {
    return this.protocolSource.getValue();
  }

  public getModelListByBrand(brandId: number): DeviceModel[] {
    return this.instantModels().filter(model => model.brand.id === brandId);
  }

  public filterModelListBySelectedModelIds(modelIdList: number[]): DeviceModel[] {
    return this.instantModels().filter(model => modelIdList.includes(model.id));
  }

  public destroy(): void {
    this.brandSource.next(null);
    this.modelSource.next(null);
    this.protocolSource.next(null);
  }
}

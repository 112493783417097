import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss']
})
export class DialogDeleteComponent {

  @Input() title = 'Example Title';
  @Input() message: string;

  @Input() disableDelete: boolean = false;

  @Input() showSpinner = false;

  @Output() delete = new EventEmitter();
  @Output() cancel = new EventEmitter();
}

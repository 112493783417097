import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AbstractFilterComponent} from "../abstract-filter.component";
import {CommonFilterComponent} from "../common-filter/common-filter.component";
import {FilterNumberRangeInputComponent} from "./filter-number-range-input/filter-number-range-input.component";

export interface NumberRangeFilter {
  start: number;
  end: number;
}

enum Control {
  NUMBER_RANGE = 'NUMBER_RANGE',
}

@Component({
  selector: 'app-number-range-filter',
  templateUrl: './number-range-filter.component.html',
  styleUrls: ['./number-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberRangeFilterComponent extends AbstractFilterComponent<NumberRangeFilter> implements OnInit {
  controls = Control;

  @ViewChild(CommonFilterComponent) filterComponent: CommonFilterComponent;
  @ViewChild('numberRangeInput', {static: false}) numberRangeInput: FilterNumberRangeInputComponent;

  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() step = 1;
  @Input() uom = '';

  public form: UntypedFormGroup;

  ngOnInit() {
    this.buildForm();
    this.viewValue = this.toViewValue(this.form.value);
  }

  private buildForm(): void {
    this.form = new UntypedFormGroup({
      [Control.NUMBER_RANGE]: new UntypedFormControl(this.filter)
    })
  }

  public onClear(): void {
    this.form.controls[Control.NUMBER_RANGE].setValue(null);
    this.onApply();
  }

  public onApply(): void {
    if (this.isFormNotEmpty()) {
      this.viewValue = this.toViewValue(this.form.value[Control.NUMBER_RANGE]);
      this.filterChange.emit(this.form.value[Control.NUMBER_RANGE]);
    } else {
      this.viewValue = null;
      this.filterChange.emit(null);
    }
    this.filterComponent.close();
  }

  private toViewValue(filter: NumberRangeFilter): string {
    if (filter.start != null || filter.end != null) {
      const start = filter.start ?? '..';
      const end = filter.end ?? '..';
      return `${start}-${end}`;
    }
    return null;
  }

  private isFormNotEmpty(): boolean {
    return this.form.value[Control.NUMBER_RANGE]
  }

  onOpen() {
    this.numberRangeInput.focus();
  }
}

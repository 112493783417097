import {Pipe, PipeTransform} from '@angular/core';
import {SensorUtils} from '../utils/sensor-utils';

@Pipe({
  name: 'sensorValue'
})

export class SensorValuePipe implements PipeTransform {
  transform(value: any, precision: number, nanToken: string = '-'): any {
    return SensorUtils.toValue(value, precision, nanToken);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from '../modelinterfaces/language.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({providedIn: 'root'})

export class LanguageService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(): Observable<Language[]> {
    const url = `${this.environmentHelper.urlV2}/language`;
    return this.http.get<Language[]>(url).pipe(
      map(list => Language.valuesOf(list))
    );
  }
}

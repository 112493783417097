import { PageModel } from './page-model.model';
import { Page } from './page.model';

export class UserShort {

  constructor(public readonly id: number,
              public name: string) {
  }

  public static valueOf(o: UserShort): UserShort {
    return o ? new UserShort(o.id, o.name) : null;
  }

  public static valuesOf(list: UserShort[]): UserShort[] {
    return list ? list.map(o => UserShort.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<UserShort>): Page<UserShort> {
    return o ? new PageModel<UserShort>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, UserShort.valuesOf(o.content)) : null;
  }
}

import { HttpParams } from '@angular/common/http';
import { Page } from './page.model';

export class PageModel<T> implements Page<T> {

  constructor(public empty: boolean,
              public first: boolean,
              public last: boolean,
              public number: number,
              public numberOfElements: number,
              public totalElements: number,
              public totalPages: number,
              public urlParams: HttpParams,
              public content: T[]) {
  }
}

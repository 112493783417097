import { Component } from '@angular/core';
import {StyledAbstractIconButtonComponent} from "../../core/icon-styled-button/styled-abstract-icon-button.component";

@Component({
  selector: 'app-icon-button-white',
  templateUrl: './icon-button-white.component.html',
})
export class IconButtonWhiteComponent extends StyledAbstractIconButtonComponent {

}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-header-title-edit-delete',
  templateUrl: './header-title-edit-delete.component.html',
  styleUrls: ['./header-title-edit-delete.component.scss']
})
export class HeaderTitleEditDeleteComponent {
  @Input() title = "@Input title@";
  @Input() showSpinner = false;
  @Input() editIcon = 'edit';
  @Input() disableEdit = false;
  @Input() disableDelete = false;
  @Input() hideDelete = false;

  @Output() edit = new EventEmitter<MouseEvent>();
  @Output() delete = new EventEmitter<MouseEvent>();
}

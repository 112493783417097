import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

@Injectable()

export class MatPaginationIntlService extends MatPaginatorIntl {
  public firstPageLabel = 'First page';
  public itemsPerPageLabel = 'Items per page';
  public lastPageLabel = 'Last page';
  public nextPageLabel = 'Next page';
  public previousPageLabel = 'Previous page';

  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
    this.translateLabels();
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = this.translate ? this.translate.instant('paginator.of') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = ((page * pageSize) > length) ?
      (Math.ceil(length / pageSize) - 1) * pageSize :
      page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  private translateLabels() {
     this.firstPageLabel = this.translate.instant('paginator.first-page');
     this.itemsPerPageLabel = this.translate.instant('paginator.items-per-page');
     this.lastPageLabel = this.translate.instant('paginator.last-page');
     this.nextPageLabel = this.translate.instant('paginator.next-page');
     this.previousPageLabel = this.translate.instant('paginator.previous-page');
     this.changes.next();
  }
}

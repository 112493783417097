import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-icon-button-attach',
  templateUrl: './icon-button-attach.component.html',
})
export class IconButtonAttachComponent extends ActionAbstractButtonComponent {
  @Output() attach = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.attach.emit(event);
  }
}

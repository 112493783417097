import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Directive({
  selector: 'ignore-selector',
})
export class StyledAbstractButtonComponent extends ActionAbstractButtonComponent {
  @Input() text = 'example';

  @Output() action = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.action.emit(event);
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Tab } from '../app-enums/tab';
import { HttpErrorExtended } from '../app-models/http-error-extended.model';
import { UiSpinnerService } from '../app-services/ui-spinner.service';
import { EnvironmentHelper } from '../services/environment-helper';
import { environment } from '../../../../user-portal/src/environments/environment';

@Injectable()

export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(private environmentHelper: EnvironmentHelper,
              private router: Router,
              private uiSpinnerService: UiSpinnerService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap(() => {
      },
      (err: any) => {
        this.uiSpinnerService.stop();
        if (!(err instanceof HttpErrorResponse)) {
          return;
        }
        if ((err.status === 401 || err.status === 0) && !err.url.includes(this.environmentHelper.urlDvr)
          && !err.url.includes(environment.SEARCH_ADDRESS_URL)) {
          this.router.navigate([Tab.LOGIN]).then();
        }
        console.error(err);
        throw new HttpErrorExtended(err, req?.method);
      }));
  }
}

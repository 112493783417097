import { Language } from './language.model';
import { Role } from "./enums/role";
import { MapCenter } from './map-center.model';
import { TimeZone } from './time-zone.model';
import { URole } from './urole.model';
import { UserShort } from './user-short.model';

export class User extends UserShort {

  constructor(
    public readonly id: number,
    public name: string,
    public owner: UserShort,
    public password: string,
    public email: string,
    public legalName: string,
    public enabled: boolean,
    public role: URole,
    public timeZone: TimeZone,
    public language: Language,
    public mapCenter: MapCenter,
  ) {
    super(id, name);
  }

  public static valueOf(i: User): User {
    return i ? new User(i.id, i.name, UserShort.valueOf(i.owner), i.password, i.email, i.legalName, i.enabled,
      URole.valueOf(i.role),
      TimeZone.valueOf(i.timeZone),
      Language.valueOf(i.language),
      MapCenter.valueOf(i.mapCenter)
    ) : null;
  }

  public static valuesOf(list: User[]): User[] {
    return list ? list.map(o => User.valueOf(o)) : [];
  }

  public isDealer(): boolean {
    return this.role.role === Role.DEALER;
  }

  public isAdmin(): boolean {
    return this.role.role === Role.ADMIN;
  }

  public getUserTimeZone(): string {
    return this.timeZone?.name;
  }
}

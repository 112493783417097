export class MapCenter {

  constructor(public latitude: number,
              public longitude: number,
              public scale: number) {
  }

  public static valueOf(i: MapCenter): MapCenter {
    return i ? new MapCenter(i.latitude, i.longitude, i.scale) : null;
  }
}

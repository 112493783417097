import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-cancel',
  templateUrl: './button-cancel.component.html'
})
export class ButtonCancelComponent extends ActionAbstractButtonComponent {
  @Input() shouldCloseDialog = true;
  @Output() cancel = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.cancel.emit(event);
  }
}

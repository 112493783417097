// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-color-orange:hover {
  color: var(--color-orange);
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFyY2hpdmFibGUtbmFtZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDBCQUFBO0FBQ0YiLCJmaWxlIjoiYXJjaGl2YWJsZS1uYW1lLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmhvdmVyLWNvbG9yLW9yYW5nZTpob3ZlciB7XHJcbiAgY29sb3I6IHZhcigtLWNvbG9yLW9yYW5nZSk7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-module/src/lib/app-components/archivable-name/archivable-name.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AACA,4WAA4W","sourcesContent":[".hover-color-orange:hover {\r\n  color: var(--color-orange);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {Subject} from "rxjs";
import {Component, OnDestroy} from "@angular/core";

/**
 * add to subscriptions pipe
 * EXAMPLE
 *   getList() {
 *     this.logisticSkillService.list()
 *       .pipe(takeUntil(this.destroy))
 *       .subscribe(s => this.skills = s);
 *   }
 */
@Component({template: ''})
export abstract class BaseUnsubscribeComponent implements OnDestroy {
  protected readonly destroy = new Subject();

  ngOnDestroy(): void {
    this.destroyAll();
  }

  protected destroyAll() {
    this.destroy.next();
    this.destroy.complete();
  }
}

declare var require: any

export const environment = {
  production: false,
  GUI_VERSION: require('../../../../package.json').version,
  BASE_URL: {
    BASE: 'https://api.aurora-soft.xyz',
    AURORA: '/aurora/api/v1',
    AURORA_V2: '/aurora/api/v2',
    DVR: '/bi-dvr/api/v1',
  },
  BASE_USER_URL: 'https://monitoring.aurora-soft.xyz',
  BASE_ADMIN_URL: 'https://admin.aurora-soft.xyz',
  HEADER: 'DEV',
  DEFAULT_LANGUAGE: 'ru',
};

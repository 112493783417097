import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-send',
  templateUrl: './button-send.component.html',
})
export class ButtonSendComponent extends ActionAbstractButtonComponent {
  @Output() send = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.send.emit(event);
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'metersToKm'
})
export class MetersToKilometersPipe implements PipeTransform {
  transform(meters: number, accuracy: number): string {
    const result: number = meters / 1000;
    return result.toFixed(accuracy);
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'cmn-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonFilterComponent {

  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @Input() name: string;
  @Input() viewValue: string;
  @Input() viewValueTruncate: number;
  @Input() disableApply: boolean = false;
  @Input() tooltip: string;
  @Input() showApplyButton = true;

  @Output() open = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();

  isOpened = false;

  close() {
    this.menuTrigger.closeMenu();
    this.onMenuClosed();
  }

  onMenuOpen() {
    this.open.emit();
    this.isOpened = true;
  }

  onMenuClosed() {
    this.isOpened = false;
  }
}

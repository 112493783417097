import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AbstractFilterComponent} from "../abstract-filter.component";
import {CommonFilterComponent} from "../common-filter/common-filter.component";

enum Control {
  SEARCH = 'search'
}

@Component({
  selector: 'app-string-filter',
  templateUrl: './string-filter.component.html',
  styleUrls: ['./string-filter.component.scss']
})
export class StringFilterComponent extends AbstractFilterComponent<string> implements OnInit {

  @ViewChild(CommonFilterComponent) filterComponent: CommonFilterComponent;

  @ViewChild("searchInput") searchInputRef: ElementRef;
  controls = Control;

  viewValue: string;

  public form: UntypedFormGroup;

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      [Control.SEARCH]: new UntypedFormControl(this.filter),
    });
    this.viewValue = this.filter;
  }

  public clearFilter(): void {
    this.form.controls[Control.SEARCH].setValue(null);
    this.onApply();
  }

  public onApply(): void {
    const value = this.form.value[Control.SEARCH];
    if (value && value.trim().length > 0) {
      this.viewValue = value;
      this.filterChange.emit(value);
    } else {
      this.viewValue = null;
      this.filterChange.emit(null);
    }
    this.filterComponent.close();
  }

  onOpen() {
    setTimeout(() => {
      this.searchInputRef.nativeElement.focus();
    }, 0);
  }
}

import {Component, OnInit} from '@angular/core';
import {ViewLanguageService} from '../../../common-module/src/lib/app-services/view-language.service';
import {AppCustomizationService} from "../../../common-module/src/lib/app-services/app-customization.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private viewLanguageService: ViewLanguageService,
              private appCustomizationService: AppCustomizationService) {
  }

  ngOnInit(): void {
    this.appCustomizationService.init();
    this.viewLanguageService.initCurrentLanguage();
  }
}

import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NEVER, Observable} from 'rxjs';

import {LocalStorageKey} from '../../../../../common-module/src/lib/app-enums/local-storage-key';
import {Tab} from '../../../../../common-module/src/lib/app-enums/tab';
import {UiSpinnerService} from '../../../../../common-module/src/lib/app-services/ui-spinner.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private uiSpinnerService: UiSpinnerService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem(LocalStorageKey.BEARER_TOKEN);
    if (this.canSendWithoutAuthToken(req)) {
      return next.handle(req);
    }
    // prevent manual set Content-Type
    if (this.isRequestWithFile(authToken, req)) {
      req = this.setHeaders(req, authToken);
      return next.handle(req);
    }
    if (authToken) {
      req = this.setHeaders(req, authToken, 'Content-Type', 'application/json');
      return next.handle(req);
    }
    this.router.navigate([Tab.LOGIN]).then();
    this.uiSpinnerService.stop();
    console.log("Request never send: ", req);
    return NEVER;
  }

  private canSendWithoutAuthToken(req: HttpRequest<any>): boolean {
    return req.url.includes('language')
      || req.url.includes('i18n')
      || (req.url.includes('token') && req.method === 'POST')
      || (req.url.includes("frontCustomization") && req.method === 'GET')
  }

  private isRequestWithFile(authToken: string, req: HttpRequest<any>) {
    return authToken &&
      ((req.url.includes('unit/import') && req.method === 'POST')
        || (req.url.includes('frontCustomization') && req.method === 'POST'));
  }

  private setHeaders(req: HttpRequest<any>, authToken: string, ...headers: string[]) {
    let result: HttpHeaders = new HttpHeaders();
    result = result.append('Authorization', `Bearer ${authToken}`);
    for (let i = 0; i < headers.length; i += 2) {
      result = result.append(headers[i], headers[i + 1]);
    }
    return req.clone({
      headers: result
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonColor} from "../../../app-styles/connector/button-color.style";

@Component({
  selector: 'app-icon-text-button-fixed-width',
  templateUrl: './icon-text-button-fixed-width.component.html',
  styleUrl: './icon-text-button-fixed-width.component.scss'
})
export class IconTextButtonFixedWidthComponent {
  @Input() icon = 'local_bar'
  @Input() text = '@Example text@'
  @Input() width = 250;
  @Input() color: ButtonColor = ButtonColor.PRIMARY;
  @Input() disabled = false;

  @Output() action = new EventEmitter<MouseEvent>();
}

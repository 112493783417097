import { Component } from '@angular/core';
import {StyledAbstractIconButtonComponent} from "../../core/icon-styled-button/styled-abstract-icon-button.component";

@Component({
  selector: 'app-icon-button-accent',
  templateUrl: './icon-button-accent.component.html',
})
export class IconButtonAccentComponent extends StyledAbstractIconButtonComponent {

}

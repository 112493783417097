export enum LocalStorageKey {

  // common keys
  BEARER_TOKEN = 'bearer_token',
  LANGUAGE = 'language',
  SENSORS_LAST_SELECTED = 'last-selected-sensors',

  // for user-portal
  CHECK_DEVICE = 'check-device',
  UNITS_SPLIT_BY_USERS = 'units-split-by-users',
  CHART_UNITE_PARTS = 'chart-unite-parts',
  CHART_CUSTOMIZATION = 'chart-customization',
  TOOLBAR_STATE = 'toolbar-state',
  REPORT_TYPE = 'report-type',
  REPORT_OPTIONS = 'report-options',
  SHOW_UNITS_ON_MAP_STATE = 'show-units-on-map-state',
  TILE_SELECTED = 'selected-tile',
  TRACK_OPTIONS = 'track-options',
  SHOW_TRACE = 'show-trace',

  // for admin-portal
  PAGE_SIZE_UNITS = 'page-size-units',
  PAGE_SIZE_USERS = 'page-size-users',
  PAGE_SIZE_TEMPLATES = 'page-size-templates',
  PAGE_SIZE_RETRANSLATORS = 'page-size-retranslators',
  PARAMETERS_LAST_SELECTED = 'last-selected-parameters',
  VIRTUAL_LAST_SELECTED = 'last-selected-virtual-parameters',
  TRACKER = 'tracker',
  BRAND = 'tracker-brand',
  MODEL = 'tracker-model',
  PROTOCOL = 'tracker-protocol'
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cmn-icon-button-base',
  templateUrl: './icon-button-base.component.html',
  styleUrls: ['./icon-button-base.component.scss']
})
export class IconButtonBaseComponent {
  @Input() styleClass: string;
  @Input() icon = 'local_bar'
  @Input() disabled = false;
  @Input() showSpinner = false;

  @Output() action = new EventEmitter<MouseEvent>();

  public emitAction($event: MouseEvent) {
    if (this.disabled || this.showSpinner) {
      $event.stopPropagation();
    } else {
      this.action.emit($event);
    }
  }
}

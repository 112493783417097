// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-menu .box-inputs {
  display: flex;
  gap: 5px;
  padding: 0 20px;
  font-size: 1.6rem;
}
.filter-menu .box-inputs mat-form-field {
  width: 250px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInN0cmluZy1maWx0ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxtQkFBQTtBQUNGO0FBQ0U7RUFDRSxhQUFBO0VBQ0EsUUFBQTtFQUNBLGVBQUE7RUFDQSxpQkFBQTtBQUNKO0FBQ0k7RUFDRSxZQUFBO0FBQ04iLCJmaWxlIjoic3RyaW5nLWZpbHRlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5maWx0ZXItbWVudSB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xyXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XHJcblxyXG4gIC5ib3gtaW5wdXRzIHtcclxuICAgIGRpc3BsYXk6IGZsZXg7XHJcbiAgICBnYXA6IDVweDtcclxuICAgIHBhZGRpbmc6IDAgMjBweDtcclxuICAgIGZvbnQtc2l6ZTogMS42cmVtO1xyXG5cclxuICAgIG1hdC1mb3JtLWZpZWxkIHtcclxuICAgICAgd2lkdGg6IDI1MHB4O1xyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/common-module/src/lib/app-components/filters/string-filter/string-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACI;EACE,YAAA;AACN;AACA,wvBAAwvB","sourcesContent":[".filter-menu {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n\r\n  .box-inputs {\r\n    display: flex;\r\n    gap: 5px;\r\n    padding: 0 20px;\r\n    font-size: 1.6rem;\r\n\r\n    mat-form-field {\r\n      width: 250px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { HttpErrorResponse } from '@angular/common/http';

export class HttpErrorExtended extends HttpErrorResponse {

  public method: string;

  constructor(error: HttpErrorResponse, method: string) {
    super(error);
    this.method = method;
  }
}

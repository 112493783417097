import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {MapCenter} from '../modelinterfaces/map-center.model';
import {UserDetails} from '../modelinterfaces/user-details.model';
import {User} from '../modelinterfaces/user.model';
import {UserDetailsService} from '../services/user-details.service';
import {UserService} from '../services/user.service';
import {UiSpinnerService} from './ui-spinner.service';
import {DateRangeI} from "../app-interfaces/date-range-i";
import {DayjsUtil} from "../dayjs.util";

@Injectable({
  providedIn: 'root'
})

export class AuthUserService {

  private _currentAuthUserSource = new BehaviorSubject<User | UserDetails>(undefined);
  public isAdmin = false;
  constructor(private uiSpinnerService: UiSpinnerService,
              private userService: UserService,
              private userDetailsService: UserDetailsService) {
  }

  get currentUser$(): Observable<User> {
    return this._currentAuthUserSource.asObservable();
  }

  get currentUserDetails$(): Observable<UserDetails> {
    return this._currentAuthUserSource.asObservable().pipe(map(user => <UserDetails>user));
  }

  get timeZone$(): Observable<string> {
    return this._currentAuthUserSource.asObservable().pipe(
      map(user => user?.timeZone?.name)
    );
  }

  public init(isAdmin = false): void {
    if (this._currentAuthUserSource.getValue()) {
      return;
    }
    this.isAdmin = isAdmin;
    this.update();
  }

  public update() {
    if (this.isAdmin) {
      this.uiSpinnerService.show();
      this.userDetailsService.getAuthUser().subscribe(user => {
        this.change(user);
        this.uiSpinnerService.stop();
      });
      return;
    }
    this.userService.getAuthUser().subscribe(user => {
      this.change(user);
    });
  }

  public change(user: User | UserDetails): void {
    this._currentAuthUserSource.next(user);
  }

  public getInstant(): User {
    return this._currentAuthUserSource.getValue();
  }

  public getInstantDetails(): UserDetails {
    return <UserDetails>this._currentAuthUserSource.getValue();
  }

  public getInstantMapCenter(): MapCenter {
    return this._currentAuthUserSource.getValue()?.mapCenter;
  }

  public getInstantTimeZone(): string {
    return this._currentAuthUserSource.getValue()?.getUserTimeZone();
  }

  public destroy(): void {
    this._currentAuthUserSource.next(null);
  }

  public getTodayRange$(): Observable<DateRangeI> {
    return this.timeZone$.pipe(
      filter(timeZone => !!timeZone),
      map(timeZone => {
        const startDate = DayjsUtil.instant().startOf('day');
        const endDate = DayjsUtil.instant().endOf('day');
        return {startDate, endDate} as DateRangeI;
      })
    );
  }
}

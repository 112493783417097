import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {
  @Input() title = '@Input Tittle@';
  @Input() showSpinner = false;
  @Input() actionIcon = 'local-bar'
  @Input() actionDisabled = false;

  @Output() action = new EventEmitter<MouseEvent>();
}

import {Pipe, PipeTransform} from '@angular/core';
import {Dayjs} from "dayjs";
import {DayjsUtil} from "../dayjs.util";


@Pipe({
  name: 'utcDateTzMoment'
})

export class MomentTimezoneConvertPipe implements PipeTransform {

  transform(datetime: string, tzName: string): Dayjs {
    if (!DayjsUtil.instant(datetime).isValid()) {
      return null;
    }
    return DayjsUtil.utc(datetime).tz(tzName);
  }
}

import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({template: ''})
export class AbstractFilterComponent<FILTER> {

  @Input() name = '';
  @Input() tooltip: string;
  /**
   * value displays current applied values
   */
  viewValue: string;

  @Input() filter: FILTER;

  /**
   * Should emit NOT NULL value if filter apply
   * Should emit NULL if filter is clear
   */
  @Output() filterChange = new EventEmitter<FILTER>();
}

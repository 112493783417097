import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-download',
  templateUrl: './button-download.component.html'
})
export class ButtonDownloadComponent extends ActionAbstractButtonComponent {

  @Output() download = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.download.emit(event);
  }
}

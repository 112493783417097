export class DeviceProtocol {

  constructor(
    public readonly id: number,
    public name: string,
    public port: number) {
  }

  public static valueOf(o: DeviceProtocol): DeviceProtocol {
    return o ? new DeviceProtocol(o.id, o.name, o.port) : null;
  }

  public static valuesOf(list: DeviceProtocol[]): DeviceProtocol[] {
    return list ? list.map(o => DeviceProtocol.valueOf(o)) : [];
  }
}

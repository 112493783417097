import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true
})

export class TruncatePipe implements PipeTransform {

  transform(value: string, limit = 25, completeWords = false): string {
    if (completeWords) {
      const index = value.substr(0, limit).lastIndexOf(' ');
      if (index > 0) {
        limit = index;
      }
    }
    return value.length > limit ? value.substr(0, limit) + '...' : value;
  }
}

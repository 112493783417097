/* eslint-disable */

// AoT requires an exported function for factories
import {TranslateLoader} from "@ngx-translate/core";
import {from, Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    // @ts-ignore
    return from(import(`../../i18n/${lang}.json`)).pipe(
      map(translations => this.replaceEmptyStrings(translations.default)),
      catchError(() => of({}))
    );
  }

  // Сrowdin при экспорте в файл для ключей без перевода ставит пустую строку
  // это плохо тем что TranslateService считает это корректным переводом и вставляет пустую строку вместо перевода
  // после замены '' на null мы можем с помощью MissingTranslationHandler управлять ситуацией когда отсуствует перевод
  // подставлять значение из default language
  /**
   * replace empty string to null
   */
  private replaceEmptyStrings(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
    const result = Array.isArray(obj) ? [] : {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      result[key] = value === '' ? null : this.replaceEmptyStrings(value);
    });
    return result;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceModel } from '../modelinterfaces/device-model.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DeviceModelService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getListByBrand(deviceBrandId: number): Observable<DeviceModel[]> {
    const url = `${this.environmentHelper.urlV2}/deviceModel`;

    let params = new HttpParams();
    params = params.set('deviceBrandId', deviceBrandId.toString());

    return this.http.get<DeviceModel[]>(url, {params: params}).pipe(
      map(list => DeviceModel.valuesOf(list))
    );
  }

  public getList(): Observable<DeviceModel[]> {
    const url = `${this.environmentHelper.urlV2}/deviceModel`;
    return this.http.get<DeviceModel[]>(url).pipe(
      map(list => DeviceModel.valuesOf(list))
    );
  }

  public getListByUser(userId: number): Observable<DeviceModel[]> {
    const url = `${this.environmentHelper.urlV2}/deviceModel`;

    let params = new HttpParams();
    params = params.set('userId', userId.toString());

    return this.http.get<DeviceModel[]>(url, {params: params}).pipe(
      map(list => DeviceModel.valuesOf(list))
    );
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {LocalStorageKey} from '../app-enums/local-storage-key';
import {EnvironmentHelper} from '../services/environment-helper';
import {Tab} from '../app-enums/tab';

@Injectable({providedIn: 'root'})
export class ViewLanguageService {

  constructor(private environmentHelper: EnvironmentHelper,
              private router: Router,
              private translateService: TranslateService) {
  }

  public initCurrentLanguage(): void {
    this.translateService.setDefaultLang(this.environmentHelper.defaultLanguage);
    let language = this.get();
    this.translateService.use(language);
  }

  public get(): string {
    const storedLanguage = window.localStorage.getItem(LocalStorageKey.LANGUAGE);
    if (storedLanguage) {
      return storedLanguage;
    }

    const interfaceLanguage = this.getLanguageByInterface();
    this.set(interfaceLanguage);
    return interfaceLanguage;
  }

  public set(l: string): boolean {
    window.localStorage.setItem(LocalStorageKey.LANGUAGE, l);
    if (l !== this.translateService.currentLang) {
      this.translateService.use(l);
      return true;
    }
    return false;
  }

  private getLanguageByInterface(): string {
    if (this.router.url.includes(Tab.PUBLIC)) {
      const userGlobalLanguage = window.navigator.language.slice(0, 2);
      this.set(userGlobalLanguage);
      return userGlobalLanguage;
    }
    return this.environmentHelper.defaultLanguage;
  }
}

export class ConnectionState {

  constructor(
    public status: string,
    public secondsAgo: number,
    public time: string) {
  }

  public static valueOf(i: ConnectionState): ConnectionState {
    return i ? new ConnectionState(i.status, i.secondsAgo, i.time) : null;
  }
}

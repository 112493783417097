import {ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractFilterComponent} from "../abstract-filter.component";
import {CommonFilterComponent} from "../common-filter/common-filter.component";
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";

enum Control {
  VALUE = "VALUE"
}

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrl: './number-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberFilterComponent extends AbstractFilterComponent<number> implements OnInit {
  controls = Control;

  @ViewChild(CommonFilterComponent) filterComponent: CommonFilterComponent;
  @ViewChild('startInput') startInputRef: ElementRef;
  @ViewChild('endInput') endInputRef: ElementRef;

  @Input() minValue = 0;
  @Input() maxValue = Number.MAX_VALUE;
  @Input() step = 1;
  @Input() uom = '';

  public form: UntypedFormGroup;

  ngOnInit() {
    this.buildForm();
    this.viewValue = this.toViewValue(this.form.value[Control.VALUE]);
  }

  private buildForm(): void {
    this.form = new UntypedFormGroup({
      [Control.VALUE]: new UntypedFormControl(this.setIncludingZeroValue(this.filter), this.setMinMaxValidators()),
    });
  }

  private setIncludingZeroValue(value: number): number {
    if (value || value === 0) {
      return value;
    }
    return null;
  }

  private setMinMaxValidators(): ValidatorFn[] {
    return [
      this.minValue !== undefined ? Validators.min(this.minValue) : null,
      this.maxValue !== undefined ? Validators.max(this.maxValue) : null,
    ].filter(validator => validator !== null) as ValidatorFn[];
  }

  public onClear(): void {
    this.form.controls[Control.VALUE].setValue(null);
    this.onApply();
  }

  public onApply(): void {
    if (this.isFormNotEmpty()) {
      const value = this.form.value[Control.VALUE];
      this.viewValue = this.toViewValue(value);
      this.filterChange.emit(value);
    } else {
      this.viewValue = null;
      this.filterChange.emit(null);
    }
    this.filterComponent.close();
  }

  private toViewValue(filter: number): string {
    return filter ? String(filter) : null;
  }

  private isFormNotEmpty(): boolean {
    return this.form.value[Control.VALUE];
  }

  onOpen() {
    setTimeout(() => {
      this.startInputRef.nativeElement.focus();
    }, 0);
  }
}

import {Inject, Injectable, InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';

export interface BaseUrl {
  BASE: string;
  AURORA: string;
  AURORA_V2: string;
  DVR: string;
}

export interface Outage {
  BASE_URL: string;
  SERVER_NAME: string;
}

export interface ToolbarRestrictions {
  DISABLE_CHART: number[];
  DISABLE_TRACK: number[];
  DISABLE_REPORT: number[];
}

export interface LogisticRestriction {
  AVAILABLE_USER_IDS: number[];
}

export const BASE_URL = new InjectionToken<BaseUrl>('BASE_URL');
export const HEADER = new InjectionToken<string>('HEADER');
export const DEFAULT_LANGUAGE = new InjectionToken<string>('DEFAULT_LANGUAGE');
export const OUTAGE = new InjectionToken<Outage>('OUTAGE');

export const TOOLBAR_RESTRICTIONS = new InjectionToken<ToolbarRestrictions>('TOOLBAR_RESTRICTIONS');

export const LOGISTIC_RESTRICTION = new InjectionToken<LogisticRestriction>('LOGISTIC_RESTRICTION');

@NgModule()
export class AppCommonEnvironmentModule {
  static forRoot(host: BaseUrl | string,
                 header: string,
                 lang: string,
                 outage: Outage,
                 toolbarRestrictions: ToolbarRestrictions,
                 logisticRestriction: LogisticRestriction,
  ): ModuleWithProviders<AppCommonEnvironmentModule> {
    return {
      ngModule: AppCommonEnvironmentModule,
      providers: [
        {
          provide: BASE_URL,
          useValue: host
        },
        {
          provide: HEADER,
          useValue: header
        },
        {
          provide: DEFAULT_LANGUAGE,
          useValue: lang
        },
        {
          provide: OUTAGE,
          useValue: outage
        },
        {
          provide: TOOLBAR_RESTRICTIONS,
          useValue: toolbarRestrictions
        },
        {
          provide: LOGISTIC_RESTRICTION,
          useValue: logisticRestriction
        },
      ]
    };
  }
}

@Injectable({
  providedIn: 'root'
})

export class EnvironmentHelper {
  baseUrl: string;
  url: string;
  urlV2: string;
  urlDvr: string;
  header: string;
  defaultLanguage: string;
  outage: Outage;
  toolbarRestrictions: ToolbarRestrictions;
  logisticRestriction: LogisticRestriction;

  constructor(@Inject(BASE_URL) _BASE_URL: BaseUrl,
              @Inject(HEADER) _HEADER: string,
              @Inject(DEFAULT_LANGUAGE) _DEFAULT_LANGUAGE: string,
              @Inject(OUTAGE) _OUTAGE: Outage,
              @Inject(TOOLBAR_RESTRICTIONS) _TOOLBAR_RESTRICTIONS: ToolbarRestrictions,
              @Inject(LOGISTIC_RESTRICTION) _LOGISTIC_RESTRICTION: LogisticRestriction
  ) {
    this.baseUrl = _BASE_URL.BASE;
    this.url = _BASE_URL.BASE + _BASE_URL.AURORA;
    this.urlV2 = _BASE_URL.BASE + _BASE_URL.AURORA_V2;
    this.urlDvr = _BASE_URL.BASE + _BASE_URL.DVR;
    this.header = _HEADER;
    this.defaultLanguage = _DEFAULT_LANGUAGE;
    this.outage = _OUTAGE;
    this.toolbarRestrictions = _TOOLBAR_RESTRICTIONS;
    this.logisticRestriction = _LOGISTIC_RESTRICTION;
  }
}

import {Component, Input} from '@angular/core';
import {AbstractFilterComponent} from "../abstract-filter.component";

export interface TranslatedValue {
  value: string;
  translate: string;
}

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.scss']
})

export class RadioFilterComponent extends AbstractFilterComponent<string> {
  @Input() itemList: TranslatedValue[] = [];

  public selectedFilter: string;

  public applyChanges(item: TranslatedValue): void {
    this.selectedFilter = item.value;
    this.viewValue = item.translate;
    this.filterChange.emit(item.value);
  }

  public onClear(): void {
    this.viewValue = null;
    this.selectedFilter = null;
    this.filterChange.emit(null);
  }
}

import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
import isoWeek from 'dayjs/plugin/isoWeek';

import 'dayjs/locale/be.js'
import 'dayjs/locale/ru.js'
import 'dayjs/locale/ne.js'
import 'dayjs/locale/de.js'
import 'dayjs/locale/el.js'
import 'dayjs/locale/es.js'
import 'dayjs/locale/fr.js'
import 'dayjs/locale/pl.js'
import 'dayjs/locale/pt.js'
import 'dayjs/locale/tr.js'
import 'dayjs/locale/uk.js'
import 'dayjs/locale/ro.js'
import 'dayjs/locale/it.js'
import 'dayjs/locale/ar.js'
import 'dayjs/locale/nl.js'


dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(isoWeek)


export class DayjsUtil {

  public static BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

  public static instant(date = undefined, format = undefined): dayjs.Dayjs {
    if (date === undefined) {
      return dayjs();
    }
    if (format) {
      return dayjs(date, format);
    } else {
      return dayjs(date);
    }
  }

  public static tz(date = undefined): dayjs.Dayjs {
    return dayjs(date).tz(this.BROWSER_TIMEZONE);
  }

  public static utc(date = undefined): dayjs.Dayjs {
    return dayjs.utc(date);
  }

  public static locale(preset: string | ILocale) {
    return dayjs.locale(preset);
  }

  public static weekdaysMin(): dayjs.WeekdayNames {
    return dayjs.weekdaysMin();
  }

  public static monthsShort(): dayjs.MonthNames {
    return dayjs.monthsShort();
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-button-back',
  templateUrl: './button-back.component.html',
})
export class ButtonBackComponent extends ActionAbstractButtonComponent {
  @Output() back = new EventEmitter<MouseEvent>();
  @Input() stepper: MatStepper | undefined;

  onAction(event: MouseEvent): void {
    if(this.stepper){
      this.stepper.previous();
    }
    this.back.emit(event);
  }
}

import {inject, NgModule} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  ResolveFn,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import {Tab} from 'projects/common-module/src/lib/app-enums/tab';
import {Observable} from "rxjs";
import {UserDetails} from "../../../common-module/src/lib/modelinterfaces/user-details.model";
import {AuthUserService} from "../../../common-module/src/lib/app-services/auth-user.service";
import {filter, take} from "rxjs/operators";
import {UserRoleListService} from "./shared/service/user-role-list.service";
import {URole} from "../../../common-module/src/lib/modelinterfaces/urole.model";
import {DeviceService} from "../../../common-module/src/lib/app-services/device.service";
import {DeviceBrand} from "../../../common-module/src/lib/modelinterfaces/device-brand.model";
import {DeviceProtocol} from "../../../common-module/src/lib/modelinterfaces/device-protocol.model";
import {SystemComponent} from "./system/system.component";


export const AuthUserResolver: ResolveFn<UserDetails> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authUserService: AuthUserService = inject(AuthUserService)
): Observable<UserDetails> => {
  authUserService.init(true);
  return authUserService.currentUserDetails$
    .pipe(
      filter<UserDetails>((user: UserDetails) => !!user),
      take(1)
    );
}

export const InitUserRoles: ResolveFn<URole[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: UserRoleListService = inject(UserRoleListService)
): Observable<URole[]> => {
  service.init();
  return service.userRoles$
    .pipe(
      filter<URole[]>((roles: URole[]) => !!roles),
      take(1)
    );
}

export const InitDeviceBrands: ResolveFn<DeviceBrand[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: DeviceService = inject(DeviceService)
): Observable<DeviceBrand[]> => {
  service.initLoadBrands();
  return service.brands$
    .pipe(
      filter<DeviceBrand[]>((brands: DeviceBrand[]) => !!brands),
      take(1)
    );
}

export const InitDeviceProtocols: ResolveFn<DeviceProtocol[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: DeviceService = inject(DeviceService)
): Observable<DeviceProtocol[]> => {
  service.initLoadProtocols();
  return service.protocols$
    .pipe(
      filter<DeviceProtocol[]>((protocols: DeviceProtocol[]) => !!protocols),
      take(1)
    );
}


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'main/users'},
  {path: Tab.LOGIN, loadChildren: () => import('./auth/auth.module').then(module => module.AuthModule)},
  {path: 'main', pathMatch: 'full', redirectTo: 'main/users'},
  {
    path: 'main',
    component: SystemComponent,
    resolve: {
      authUser: AuthUserResolver,
      roles: InitUserRoles,
      deviceBrands: InitDeviceBrands,
      deviceProtocols: InitDeviceProtocols
    },
    loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}


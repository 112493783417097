// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

mat-form-field {
  margin-bottom: 8px;
}

.box-inputs {
  display: flex;
  gap: 5px;
  padding: 0 20px;
  font-size: 1.6rem;
}
.box-inputs .extra-text {
  line-height: 72px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZpbHRlci1udW1iZXItcmFuZ2UtaW5wdXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxrQkFBQTtBQUNGOztBQUVBO0VBQ0UsYUFBQTtFQUNBLFFBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7QUFDRjtBQUNFO0VBQ0UsaUJBQUE7QUFDSiIsImZpbGUiOiJmaWx0ZXItbnVtYmVyLXJhbmdlLWlucHV0LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xyXG4gIHdpZHRoOiAxMDAlO1xyXG59XHJcblxyXG5tYXQtZm9ybS1maWVsZCB7XHJcbiAgbWFyZ2luLWJvdHRvbTogOHB4O1xyXG59XHJcblxyXG4uYm94LWlucHV0cyB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBnYXA6IDVweDtcclxuICBwYWRkaW5nOiAwIDIwcHg7XHJcbiAgZm9udC1zaXplOiAxLjZyZW07XHJcblxyXG4gIC5leHRyYS10ZXh0IHtcclxuICAgIGxpbmUtaGVpZ2h0OiA3MnB4O1xyXG4gIH1cclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./projects/common-module/src/lib/app-components/filters/number-range-filter/filter-number-range-input/filter-number-range-input.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;AACF;AACE;EACE,iBAAA;AACJ;AACA,wvBAAwvB","sourcesContent":[":host {\r\n  width: 100%;\r\n}\r\n\r\nmat-form-field {\r\n  margin-bottom: 8px;\r\n}\r\n\r\n.box-inputs {\r\n  display: flex;\r\n  gap: 5px;\r\n  padding: 0 20px;\r\n  font-size: 1.6rem;\r\n\r\n  .extra-text {\r\n    line-height: 72px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

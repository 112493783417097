import {BaseUnsubscribeComponent} from "../../../../../../shared/components/base-unsubscribe.component";
import {ControlValueAccessor, UntypedFormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

export abstract class AbstractControlValueAccessor<T> extends BaseUnsubscribeComponent implements ControlValueAccessor {

  form: UntypedFormGroup;

  protected constructor(form: UntypedFormGroup) {
    super();
    this.form = form;
    this.handleFormStatusChanges();
  }

  protected abstract convertFormToValue(): T;

  protected abstract convertValueToForm(value: T): { [key: string]: any };

  writeValue(value: object): void {
    this.form.setValue(this.convertValueToForm(value as unknown as T), {emitEvent: false});
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onChange: (value: T) => void = () => {
  };
  onTouched: () => void = () => {
  };

  protected handleFormStatusChanges() {
    this.form.statusChanges.pipe(takeUntil(this.destroy)).subscribe((value: any) => {
      if (value === 'VALID') {
        this.onChange(this.convertFormToValue());
      } else if (value === 'INVALID') {
        this.onChange(null);
      }
    });
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-helper-info',
  templateUrl: './helper-info.component.html',
  styleUrls: ['./helper-info.component.scss']
})

export class HelperInfoComponent {

  @Input() info: string;
}

export class TimeZone {

  constructor(public id: number,
              public name: string,
              public offset: number,
              public rawOffset: number,
              public description: string) {
  }

  public static valueOf(i: TimeZone): TimeZone {
    return i ? new TimeZone(i.id, i.name, i.offset, i.rawOffset, i.description) : null;
  }

  public static valuesOf(list: TimeZone[]): TimeZone[] {
    return list ? list.map(o => TimeZone.valueOf(o)).sort((a, b) => a.rawOffset - b.rawOffset) : [];
  }
}

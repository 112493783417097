import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";


@Component({
  selector: 'app-icon-button-clear',
  templateUrl: './icon-button-clear.component.html',
})
export class IconButtonClearComponent extends ActionAbstractButtonComponent {
  @Output() clear = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.clear.emit(event);
  }
}

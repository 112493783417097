import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customization } from '../modelinterfaces/customization.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class CustomizationService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getSettings(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization`;
    return this.http.get<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public changeFaviconText(text: string): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/faviconText`;
    return this.http.patch<Customization>(url, {title: text}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetFaviconText(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/faviconText`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public changeTitle(title: string): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/title`;
    return this.http.patch<Customization>(url, {title: title}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetTitle(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/title`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public changeTheme(theme: string): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/theme`;
    return this.http.patch<Customization>(url, {theme: theme}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetTheme(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/theme`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public setFavicon(img: File): Observable<Customization> {
    const formData = new FormData();
    formData.append('image', img);
    const url = `${this.environmentHelper.urlV2}/frontCustomization/upload/favicon`;
    return this.http.post<Customization>(url, formData).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetFavicon(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/favicon`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public setLogo(img: File): Observable<Customization> {
    const formData = new FormData();
    formData.append('image', img);
    const url = `${this.environmentHelper.urlV2}/frontCustomization/upload/logo`;
    return this.http.post<Customization>(url, formData).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetLogo(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/logo`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  changeDomainUser(domainUser: string) {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/domainUser`;
    return this.http.patch<Customization>(url, {domainUser: domainUser}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetDomainUser(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/domainUser`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  changeDomainAdmin(domainAdmin: string) {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/domainAdmin`;
    return this.http.patch<Customization>(url, {domainAdmin: domainAdmin}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetDomainAdmin(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/domainAdmin`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  changeDeviceServer(domainAdmin: string) {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/deviceServer`;
    return this.http.patch<Customization>(url, {deviceServer: domainAdmin}).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }

  public resetDeviceServer(): Observable<Customization> {
    const url = `${this.environmentHelper.urlV2}/frontCustomization/deviceServer`;
    return this.http.delete<Customization>(url).pipe(
      map(obj => Customization.valueOf(obj))
    );
  }
}

import {OverlayModule} from '@angular/cdk/overlay';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AppCommonModule} from '../../../common-module/src/lib/app-common.module';
import {GlobalErrorHandler} from '../../../common-module/src/lib/interceptors/global-error-handler';
import {HttpErrorsInterceptor} from '../../../common-module/src/lib/interceptors/http-errors.interceptor';
import {AppCommonEnvironmentModule} from '../../../common-module/src/lib/services/environment-helper';
import {MatPaginationIntlService} from '../../../common-module/src/lib/services/mat-pagination-intl.service';
import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';
import {AuthInterceptor} from './shared/interceptors/auth.interceptor';
import {LazyTranslateLoader} from "../../../common-module/src/lib/translate-config/LazyTranslateLoader";
import {filter, first} from "rxjs/operators";
import {AppCustomizationService} from "../../../common-module/src/lib/app-services/app-customization.service";
import {initTranslationService} from "../../../user-portal/src/app/shared/config/translation.config";

export function initAppCustomization(service: AppCustomizationService) {
  return () => {
    service.init();
    return service.customization$.pipe(
      filter(customization => customization !== null),
      first()
    );
  }
}



@NgModule({
  exports: [],
  declarations: [
    AppComponent
  ],
  imports: [
    AppCommonEnvironmentModule.forRoot(environment.BASE_URL, environment.HEADER, environment.DEFAULT_LANGUAGE, null, null, null),
    BrowserModule,
    AppCommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OverlayModule, // for UiSpinnerService
    HttpClientModule, // for TranslateModule
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    })
  ],

  providers: [
    {provide: MatPaginatorIntl, useClass: MatPaginationIntlService},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslationService,
      deps: [TranslateService, Injector],
      multi: true
    },

    {
      provide: APP_INITIALIZER,
      useFactory: initAppCustomization,
      deps: [AppCustomizationService, Injector],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  align-items: center;
  padding: 0.5em;
}

.header-label {
  flex: 1;
  height: 1em;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRhdGUtcGlja2VyLWhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLGNBQUE7QUFDRjs7QUFFQTtFQUNFLE9BQUE7RUFDQSxXQUFBO0VBQ0EsaUJBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0FBQ0YiLCJmaWxlIjoiZGF0ZS1waWNrZXItaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmhlYWRlciB7XHJcbiAgZGlzcGxheTogZmxleDtcclxuICBhbGlnbi1pdGVtczogY2VudGVyO1xyXG4gIHBhZGRpbmc6IDAuNWVtO1xyXG59XHJcblxyXG4uaGVhZGVyLWxhYmVsIHtcclxuICBmbGV4OiAxO1xyXG4gIGhlaWdodDogMWVtO1xyXG4gIGZvbnQtc2l6ZTogMS40cmVtO1xyXG4gIGZvbnQtd2VpZ2h0OiA1MDA7XHJcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/common-module/src/lib/app-components/date-picker-header/date-picker-header.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,OAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACA,4qBAA4qB","sourcesContent":[".header {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 0.5em;\r\n}\r\n\r\n.header-label {\r\n  flex: 1;\r\n  height: 1em;\r\n  font-size: 1.4rem;\r\n  font-weight: 500;\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {Observable} from 'rxjs';
import {LocalStorageKey} from '../../app-enums/local-storage-key';

import {AuthUserService} from '../../app-services/auth-user.service';
import {DateRangeI} from '../../app-interfaces/date-range-i';
import {ViewBreakpointService, ViewSize} from '../../app-services/view-breakpoint.service';
import {CapitalizePipe} from '../../pipes/capitalize.pipe';

import {Dayjs} from "dayjs";
import {DayjsUtil} from "../../dayjs.util";
import {DaterangepickerDirective, LocaleConfig} from "ngx-daterangepicker-material-no-utc";


DayjsUtil.locale(window.localStorage.getItem(LocalStorageKey.LANGUAGE));

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateRangePickerComponent implements OnChanges, OnInit {

  @Input() actionButtons = false;
  @Input() dateTimeRange: DateRangeI;
  @Input() dayLimit: number;
  @Input() isClearButton = false;
  @Input() isFuturePeriod = false;
  @Input() isSinglePicker = false;
  @Input() minDate: Dayjs;
  @Input() maxDate: Dayjs;
  @Input() placeholderName: string;
  @Input() position: string;

  @Output() dateTimeRangeChange = new EventEmitter<DateRangeI>();

  @ViewChild(DaterangepickerDirective, {static: false}) pickerDirective: DaterangepickerDirective;

  public locale: LocaleConfig = {
    customRangeLabel: ' - ',
    daysOfWeek: DayjsUtil.weekdaysMin(),
    monthNames: DayjsUtil.monthsShort(),
  };

  public ranges: { [p: string]: [Dayjs, Dayjs] };
  public link: string;
  public pickerMaxDate: Dayjs;
  public size$: Observable<ViewSize>;

  constructor(private authUserService: AuthUserService,
              private capitalizePipe: CapitalizePipe,
              private translateService: TranslateService,
              private viewBreakpointService: ViewBreakpointService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isFuturePeriod']) {
      this.pickerMaxDate = DayjsUtil.instant().add(90, 'days');
      this.ranges = this.createFutureRanges();
    }
    if (changes['maxDate']) {
      this.pickerMaxDate = this.maxDate;
    }
  }

  ngOnInit(): void {
    this.size$ = this.viewBreakpointService.size$;
    this.locale.cancelLabel = this.translateService.instant('action.cancel');

    if (!this.pickerMaxDate) {
      this.pickerMaxDate = this.getToday();
    }
    if (!this.ranges) {
      this.ranges = this.createRanges();
    }
  }

  private getToday(): Dayjs {
    return DayjsUtil.instant().endOf('day');
  }

  private createFutureRanges(): { [p: string]: [Dayjs, Dayjs] } {
    const timeZone = this.authUserService.getInstantTimeZone();
    return {
      [this.translateService.instant('date-range.today')]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().endOf('day')
        ],
      [this.translateService.instant('date-range.current-week')]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().endOf('isoWeek')
        ],
      [this.translateService.instant('date-range.current-month')]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().endOf('month'),
        ],
      [this.translateService.instant('date-range.amount-days', {amount: 15})]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().add(14, 'day').endOf('day')
        ],
      [this.translateService.instant('date-range.amount-days', {amount: 30})]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().add(29, 'day').endOf('day')
        ],
      [this.translateService.instant('date-range.amount-days', {amount: 45})]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().add(44, 'day').endOf('day')
        ],
      [this.translateService.instant('date-range.amount-days', {amount: 60})]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().add(59, 'day').endOf('day')
        ],
      [this.translateService.instant('date-range.amount-days', {amount: 90})]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().add(89, 'day')
        ],
    };
  }

  private createRanges(): { [p: string]: [Dayjs, Dayjs] } {
    const lastMonthName = DayjsUtil.instant().add(-1, 'month').startOf('month').format('MMMM');
    const lastLastMonthName = DayjsUtil.instant().add(-2, 'month').startOf('month').format('MMMM');
    const timeZone = this.authUserService.getInstantTimeZone();

    return {
      [this.translateService.instant('date-range.last-hour')]:
        [
          DayjsUtil.instant().add(-1, 'hour'),
          DayjsUtil.instant()
        ],
      [this.translateService.instant('date-range.today')]:
        [
          DayjsUtil.instant().startOf('day'),
          DayjsUtil.instant().endOf('day')
        ],
      [this.translateService.instant('date-range.yesterday')]:
        [
          DayjsUtil.instant().add(-1, 'days').startOf('day'),
          DayjsUtil.instant().add(-1, 'days').endOf('day')
        ],
      [this.translateService.instant('date-range.current-week')]:
        [
          DayjsUtil.instant().startOf('isoWeek'),
          DayjsUtil.instant().endOf('day')
        ],
      [this.translateService.instant('date-range.current-month')]:
        [
          DayjsUtil.instant().startOf('month'),
          DayjsUtil.instant().endOf('day'),
        ],
      [this.translateService.instant('date-range.last-week')]:
        [
          DayjsUtil.instant().add(-1, 'week').startOf('isoWeek'),
          DayjsUtil.instant().add(-1, 'week').endOf('isoWeek')
        ],
      [this.capitalizePipe.transform(lastMonthName)]:
        [
          DayjsUtil.instant().add(-1, 'month').startOf('month'),
          DayjsUtil.instant().add(-1, 'month').endOf('month')
        ],
      [this.capitalizePipe.transform(lastLastMonthName)]:
        [
          DayjsUtil.instant().add(-2, 'month').startOf('month'),
          DayjsUtil.instant().add(-2, 'month').endOf('month')
        ]
    };
  }

  public clear(): void {
    this.dateTimeRangeChange.emit(null);
  }

  public onChangeTimeRange(range: DateRangeI): void {
    if (!range.startDate) {
      return;
    }
    this.dateTimeRangeChange.emit(range);
  }

  public onClickRange(): void {
    // directive keepCalendarOpeningWithRange is also required to update on the range calendar
    if (!this.actionButtons) {
      this.closeDatepicker();
    }
  }

  private closeDatepicker() {
    this.pickerDirective.hide();
  }
}

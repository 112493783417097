import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderTitleComponent} from "./header-with-spinner/header-title.component";
import {HeaderTitleEditDeleteComponent} from "./header-edit-delete/header-title-edit-delete.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {AppButtonModule} from "../buttons/app-button.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    HeaderTitleComponent,
    HeaderTitleEditDeleteComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    AppButtonModule,
    MatTooltipModule,
    TranslateModule
  ],
  exports: [
    HeaderTitleComponent,
    HeaderTitleEditDeleteComponent
  ]
})
export class HeaderTitleModule {
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 150px;
  margin: 16px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm51bWJlci1maWx0ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0E7RUFDRSxZQUFBO0VBQ0EsWUFBQTtBQUFGIiwiZmlsZSI6Im51bWJlci1maWx0ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcclxubWF0LWZvcm0tZmllbGQge1xyXG4gIHdpZHRoOiAxNTBweDtcclxuICBtYXJnaW46IDE2cHg7XHJcbn1cclxuIl19 */`, "",{"version":3,"sources":["webpack://./projects/common-module/src/lib/app-components/filters/number-filter.component.ts/number-filter.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,YAAA;AAAF;AACA,gXAAgX","sourcesContent":["\r\nmat-form-field {\r\n  width: 150px;\r\n  margin: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

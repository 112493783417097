import {Directive, Input} from '@angular/core';

@Directive({
  selector: 'ignore-selector',
})
export abstract class ActionAbstractButtonComponent {
  @Input() disabled = false;
  @Input() showSpinner = false;

  //emit action in output
  onAction(event: MouseEvent): void {
  }
}

export enum Tab {
  LOGIN = 'login',

  // for user-portal
  MAP = 'map',
  ECO_DRIVING = 'eco-driving',
  DRIVERS = 'drivers',
  SENSORS = 'sensors',
  REPORT = 'report',
  CHART = 'chart',
  VEHICLE = 'vehicle',
  NOTIFICATION = 'notification',
  VIDEO = 'video',
  LOGISTIC = 'logistic',
  COMMAND_TEMPLATES = 'command-templates',
  PUBLIC = 'public',
  TELEGRAM = 'telegram-chat/activation',

  // for admin-portal
  MAIN='main',

  USERS = 'users',
  UNITS = 'units',
  TEMPLATES = 'templates',
  RETRANSLATORS = 'retranslators',
  BRAND = 'branding',
}

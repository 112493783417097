import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from '../modelinterfaces/language.model';
import { MapCenter } from '../modelinterfaces/map-center.model';
import { TimeZone } from '../modelinterfaces/time-zone.model';
import { User } from '../modelinterfaces/user.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getById(userId: number): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}`;
    return this.http.get<User>(url).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public getAuthUser(): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/auth`;
    return this.http.get<User>(url).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public save(user: User): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user`;
    return this.http.post<User>(url, user).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public update(user: User): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${user.id}`;
    return this.http.put<User>(url, user).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public changeStatus(userId: number, isEnable: boolean): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/enable`;
    return this.http.patch<User>(url, {enabled: isEnable}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public changePasswordByAdmin(userId: number, password: string): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/adminChangePassword`;
    return this.http.patch<User>(url, {newPassword: password}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public checkPassword(userId: number, password: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/password/check`;
    return this.http.post<boolean>(url, {password: password});
  }

  public changePassword(userId: number, oldPassword: string, newPassword: string): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/password`;
    return this.http.patch<User>(url, {newPassword: newPassword, oldPassword: oldPassword}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public changeLanguageByName(userId: number, lang: string): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/language/name`;
    return this.http.patch<User>(url, {name: lang}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public changeTimeZone(userId: number, timezone: TimeZone): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/timezone`;
    return this.http.patch<User>(url, {timeZone: timezone}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public changeMapCenter(userId: number, mapCenter: MapCenter): Observable<User> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}/mapCenter`;
    return this.http.patch<User>(url, {mapCenter: mapCenter}).pipe(
      map(obj => User.valueOf(obj))
    );
  }

  public delete(userId: number): Observable<void> {
    const url = `${this.environmentHelper.urlV2}/user/${userId}`;
    return this.http.delete<void>(url);
  }

  public isExistName(value: string, name: string): Observable<boolean> {
    const url = `${this.environmentHelper.urlV2}/user/existBy`;
    let params = new HttpParams();
    params = params.set(name, value);
    return this.http.get<boolean>(url, {params: params});
  }
}

export class DeviceBrand {

  constructor(public readonly id: number,
              public name: string) {
  }

  public static valueOf(o: DeviceBrand): DeviceBrand {
    return o ? new DeviceBrand(o.id, o.name) : null;
  }

  public static valuesOf(list: DeviceBrand[]): DeviceBrand[] {
    return list ? list.map(o => DeviceBrand.valueOf(o)) : [];
  }
}

import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorExtended} from '../app-models/http-error-extended.model';
import {AppMessageService} from '../app-services/app-message.service';

enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE'
}

@Injectable({
  providedIn: 'root'
})

export class GlobalErrorHandler implements ErrorHandler {

  constructor(private appMessageService: AppMessageService) {
  }

  handleError(error) {
    console.trace(error)
    if (!(error instanceof HttpErrorExtended)) {
      return;
    }
    if (error?.method === RequestMethod.Get) {
      this.appMessageService.openSnackBar('message.error.load-data', true);
    }
    if (error?.method === RequestMethod.Post || error?.method === RequestMethod.Put || error?.method === RequestMethod.Patch) {
      this.appMessageService.openSnackBar('message.error.error-while-saving', true);
    }
    if (error?.method === RequestMethod.Delete) {
      this.appMessageService.openSnackBar('message.error.error-while-deleting', true)
    }
  }
}

import { Component } from '@angular/core';
import {StyledAbstractIconButtonComponent} from "../../core/icon-styled-button/styled-abstract-icon-button.component";

@Component({
  selector: 'app-icon-button-green',
  templateUrl: './icon-button-green.component.html'
})
export class IconButtonGreenComponent extends StyledAbstractIconButtonComponent {

}

export class Language {

  constructor(public id: number,
              public name: string,
              public localName: string) {
  }

  public static valueOf(i: Language): Language {
    return new Language(i.id, i.name, i.localName);
  }

  public static valuesOf(list: Language[]): Language[] {
    return list ? list.map(o => Language.valueOf(o)) : [];
  }
}

import {Component, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Overlay} from '@angular/cdk/overlay';
import {map, scan} from 'rxjs/operators';
import {ComponentPortal} from '@angular/cdk/portal';
import {MatProgressSpinner, MatProgressSpinnerModule, MatSpinner} from '@angular/material/progress-spinner';
import {AppCommonModule} from "../app-common.module";
import {MaterialModule} from "../material.module";

@Injectable({
  providedIn: 'root',
})

export class UiSpinnerService {

  private spinnerTopRef = this.cdkSpinnerCreate();
  private spin$: Subject<boolean> = new Subject();

  constructor(
    private overlay: Overlay,
  ) {

    this.spin$
      .asObservable()
      .pipe(
        map(val => val ? 1 : -1),
        scan((acc, one) => (acc + one) >= 0 ? acc + one : 0, 0)
      )
      .subscribe(
        (res) => {
          if (res === 1) {
            this.show();
          } else if (res === 0 && this.spinnerTopRef.hasAttached()) {
            this.stop();
          }
        }
      );
  }

  private cdkSpinnerCreate() {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }

  public show(): void {
    if (!this.spinnerTopRef.hasAttached()) {
      this.spinnerTopRef.attach(new ComponentPortal(AppSpinnerComponent));
    }
  }

  public stop(): void {
    this.spinnerTopRef.detach();
  }

  public run(state: boolean) {
    if (state) {
      this.show();
    } else {
      this.stop();
    }
  }
}

@Component({
  selector: 'app-spinner',
  template: `
    <mat-spinner color="warn"></mat-spinner>`,
  standalone: true,
  imports: [
    MatProgressSpinnerModule
  ],
})
class AppSpinnerComponent {
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {URole} from '../../../../../common-module/src/lib/modelinterfaces/urole.model';
import {UserRoleService} from '../../../../../common-module/src/lib/services/user-role.service';

@Injectable({
  providedIn: 'root'
})

export class UserRoleListService {

  private userRolesSource = new BehaviorSubject<URole[]>(null);
  private _userRoles$ = this.userRolesSource.asObservable();

  constructor(private userRoleService: UserRoleService) {
  }

  get userRoles$(): Observable<URole[]> {
    return this._userRoles$;
  }

  public init(): void {
    this.userRoleService.getList().subscribe(list => {
      this.userRolesSource.next(list);
    });
  }

  public instantRoles(): URole[] {
    return this.userRolesSource.getValue();
  }

  public destroy(): void {
    this.userRolesSource.next(null);
  }
}

export class SensorUtils {

  /** returned value can be NaN */
  public static toValue(value: any, precision: number, nanToken: string = '-'): string {
    if (isNaN(value) || value === null || value === undefined) {
      return nanToken;
    }
    const pow = Math.pow(10, precision);
    return String(Math.round(value * pow) / pow);
  }
}

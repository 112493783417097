import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '../../../../common-module/src/lib/app-services/device.service';
import { UserRoleListService } from '../shared/service/user-role-list.service';
import {AppCustomizationService} from "../../../../common-module/src/lib/app-services/app-customization.service";

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html'
})
export class SystemComponent implements OnInit, OnDestroy {

  constructor(private appCustomizationService: AppCustomizationService,
              private deviceService: DeviceService,
              private userRoleListService: UserRoleListService) {
  }

  ngOnInit(): void {
    this.appCustomizationService.init();
  }

  ngOnDestroy(): void {
    this.userRoleListService.destroy();
    this.deviceService.destroy();
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'open-button',
  templateUrl: './open-button.component.html',
  styleUrls: ['./open-button.component.scss']
})
export class OpenButtonComponent {
  @Input() tooltip: string;
  @Input() viewValue: string;
  @Input() name: string;
  @Input() viewValueTruncate: number;

  @Output() click = new EventEmitter<void>();
}

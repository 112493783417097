import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-button-next',
  templateUrl: './button-next.component.html'
})
export class ButtonNextComponent extends ActionAbstractButtonComponent {
  @Output() next = new EventEmitter<MouseEvent>();
  @Input() stepper: MatStepper | undefined;

  onAction(event: MouseEvent): void {
    if (this.stepper) {
      this.stepper.next();
    }
    this.next.emit(event);
  }
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberRangeFilterComponent} from "./number-range-filter/number-range-filter.component";
import {CommonFilterComponent} from "./common-filter/common-filter.component";
import {StringFilterComponent} from "./string-filter/string-filter.component";
import {CheckboxListFilterComponent} from "./checkbox-list-filter/checkbox-list-filter.component";
import {RadioFilterComponent} from "./radio-filter/radio-filter.component";
import {DateRangeFilterComponent} from "./date-range-filter/date-range-filter.component";
import {NumberFilterComponent} from "./number-filter.component.ts/number-filter.component";
import {SearchComponent} from "../search/search.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatListOption, MatSelectionList} from "@angular/material/list";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";
import {MatMenu, MatMenuContent, MatMenuTrigger} from "@angular/material/menu";
import {MatButton, MatIconButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {TruncatePipe} from "../../pipes/truncate.pipe";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material-no-utc";
import {MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {AbstractFilterComponent} from "./abstract-filter.component";
import {BaseFilterComponent} from "./base-filter.component";
import {
  FilterNumberRangeInputComponent
} from "./number-range-filter/filter-number-range-input/filter-number-range-input.component";


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  declarations: [
    NumberRangeFilterComponent,
    CommonFilterComponent,
    StringFilterComponent,
    CheckboxListFilterComponent,
    RadioFilterComponent,
    DateRangeFilterComponent,
    NumberFilterComponent,
    AbstractFilterComponent,
    BaseFilterComponent,
    FilterNumberRangeInputComponent
  ],
  imports: [
    CommonModule,
    SearchComponent,
    TranslateModule,
    MatCheckbox,
    MatSelectionList,
    FormsModule,
    MatListOption,
    MatTooltip,
    MatMenuTrigger,
    MatButton,
    MatIcon,
    TruncatePipe,
    MatMenu,
    MatMenuContent,
    NgxDaterangepickerMd,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatRadioGroup,
    MatRadioButton,
    MatSuffix,
    MatIconButton
  ],
  exports: [
    NumberRangeFilterComponent,
    StringFilterComponent,
    CheckboxListFilterComponent,
    RadioFilterComponent,
    DateRangeFilterComponent,
    NumberFilterComponent,
    CommonFilterComponent,
    FilterNumberRangeInputComponent
  ]
})
export class FiltersModule {
}

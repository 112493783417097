import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-upload-file-input',
  templateUrl: './upload-file-input.component.html',
  styleUrl: './upload-file-input.component.scss'
})
export class UploadFileInputComponent implements OnInit {

  // расширения или типы файлов image/*,.pdf
  @Input() accept: string[] = [];

  @Output() upload = new EventEmitter<FileList>();

  accepts = '';

  ngOnInit(): void {
    this.accepts = this.accept?.join(", ") ?? '';
  }
}

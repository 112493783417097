import { ConnectionState } from './connection-state.model';
import { Language } from './language.model';
import { MapCenter } from './map-center.model';
import { PageModel } from './page-model.model';
import { Page } from './page.model';
import { TimeZone } from './time-zone.model';
import { URole } from './urole.model';
import { UserShort } from './user-short.model';
import { User } from './user.model';

export class UserDetails extends User {

  constructor(
    public readonly id: number,
    public name: string,
    public owner: UserShort,
    public password: string,
    public email: string,
    public legalName: string,
    public enabled: boolean,
    public role: URole,
    public timeZone: TimeZone,
    public language: Language,
    public mapCenter: MapCenter,
    public ownUnitCount: number,
    public sharedUnitCount: number,
    public connectionState: ConnectionState) {
    super(id, name, owner, password, email, legalName, enabled, role, timeZone, language, mapCenter);
  }

  public static valueOf(o: UserDetails): UserDetails {
    return o ? new UserDetails(
      o.id,
      o.name,
      UserShort.valueOf(o.owner),
      null,
      o.email,
      o.legalName,
      o.enabled,
      URole.valueOf(o.role),
      TimeZone.valueOf(o.timeZone),
      Language.valueOf(o.language),
      MapCenter.valueOf(o.mapCenter),
      o.ownUnitCount,
      o.sharedUnitCount,
      ConnectionState.valueOf(o.connectionState)
    ) : null;
  }

  public static valuesOf(list: UserDetails[]): UserDetails[] {
    return list ? list.map(o => UserDetails.valueOf(o)) : [];
  }

  public static pageableValueOf(o: Page<UserDetails>): Page<UserDetails> {
    return o ? new PageModel<UserDetails>(o.empty, o.first, o.last, o.number, o.numberOfElements, o.totalElements, o.totalPages,
      o.urlParams, UserDetails.valuesOf(o.content)) : null;
  }
}

export class URole {

  constructor(public readonly id: number,
              public role: string) {
  }

  public static valueOf(o: URole): URole {
    return o ? new URole(o.id, o.role) : null;
  }

  public static valuesOf(list: URole[]): URole[] {
    return list ? list.map(o => URole.valueOf(o)) : [];
  }
}

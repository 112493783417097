import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-save',
  templateUrl: './button-save.component.html'
})
export class ButtonSaveComponent extends ActionAbstractButtonComponent {

  @Output() save = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.save.emit(event);
  }
}

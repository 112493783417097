import {Component, EventEmitter, Output} from '@angular/core';
import {ActionAbstractButtonComponent} from "../../../common/concrete-button/action-abstract-button.component";

@Component({
  selector: 'app-button-edit',
  templateUrl: './button-edit.component.html'
})
export class ButtonEditComponent extends ActionAbstractButtonComponent {
  @Output() edit = new EventEmitter<MouseEvent>();

  onAction(event: MouseEvent): void {
    this.edit.emit(event);
  }
}

import { HttpParams } from '@angular/common/http';
import { PageParam } from '../modelinterfaces/enums/page-param';
import { PageParams } from '../modelinterfaces/page-params.model';

export class HttpParamsUtil {

  public static createByPage(pageParams: PageParams): HttpParams {
    let params = new HttpParams();
    Object.keys(PageParam).forEach(key => {
      params = params.set(key.toLowerCase(), String(pageParams[PageParam[key]]));
    });
    return params;
  }

  public static createByPageAndFilter(pageParams: PageParams, filter: Map<string, string>): HttpParams {
    let params = HttpParamsUtil.createByPage(pageParams);
    if (filter.size > 0) {
      filter.forEach((value: string, key: string) => params = params.set(key, value));
    }
    return params;
  }

  public static createByLang(lang: string): HttpParams {
    let params = new HttpParams();
    params = params.set('lang', lang);
    return params;
  }

  public static createByName(name: string): HttpParams {
    let params = new HttpParams();
    params = params.set('name', name);
    return params;
  }

  public static extractValueWithoutOperator(value: string): string {
    const arr = value.split('#');
    return arr[arr.length - 1];
  }
}

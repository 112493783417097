import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customization } from '../modelinterfaces/customization.model';
import { CustomizationService } from '../services/customization.service';

@Injectable({
  providedIn: 'root'
})

export class AppCustomizationService {

  private readonly DEFAULT_THEME = 'DEFAULT';
  private readonly DEFAULT_FAVICON = '../../../../favicon.ico';
  private readonly DEFAULT_TITLE = 'Aurora';

  private _customizationSource = new BehaviorSubject<Customization>(null);
  private _customization$: Observable<Customization> = this._customizationSource.asObservable();

  private currentTheme: string;
  private favIcon: HTMLLinkElement = document.getElementById('favicon') as HTMLLinkElement;
  private renderer: Renderer2;
  private themeElement = document.getElementById('body');

  constructor(private customizationService: CustomizationService,
              private rendererFactory: RendererFactory2,
              private titleService: Title) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get customization$(): Observable<Customization> {
    return this._customization$;
  }

  public init(): void {
    this.customizationService.getSettings().subscribe(settings => {
        this.changeCustomization(settings);
      });
  }

  public changeCustomization(customization: Customization): void {
    this._customizationSource.next(customization);
    if (customization) {
      this.changeThemeApp(customization.theme);
      this.changeFavicon(customization.faviconUrl);
      this.titleService.setTitle(customization.faviconText);
    } else {
      this.changeThemeApp(this.DEFAULT_THEME);
      this.changeFavicon(this.DEFAULT_FAVICON);
      this.titleService.setTitle(this.DEFAULT_TITLE);
    }
  }

  private changeThemeApp(theme: string): void {
    this.renderer.removeClass(this.themeElement, 'theme-' + this.currentTheme);
    this.currentTheme = theme;
    this.renderer.addClass(this.themeElement, 'theme-' + this.currentTheme);
  }

  private changeFavicon(url: string): void {
    if (url) {
      this.favIcon.href = url;
    }
  }

  public destroy(): void {
    this.changeCustomization(null);
  }
}

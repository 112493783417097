import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceBrand } from '../modelinterfaces/device-brand.model';
import { EnvironmentHelper } from './environment-helper';

@Injectable({
  providedIn: 'root'
})

export class DeviceBrandService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  getList(): Observable<DeviceBrand[]> {
    const url = `${this.environmentHelper.urlV2}/deviceBrand`;
    return this.http.get<DeviceBrand[]>(url).pipe(
      map(list => DeviceBrand.valuesOf(list))
    );
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageParamsI } from '../app-interfaces/page-params-i.model';
import { PageParam } from '../modelinterfaces/enums/page-param';
import { Page } from '../modelinterfaces/page.model';
import { UserDetails } from '../modelinterfaces/user-details.model';
import { HttpParamsUtil } from '../utils/http-params-util';
import { EnvironmentHelper } from './environment-helper';
import {PageParams} from "../modelinterfaces/page-params.model";

@Injectable({
  providedIn: 'root'
})

export class UserDetailsService {

  constructor(private http: HttpClient, private environmentHelper: EnvironmentHelper) {
  }

  public getList(pageParams: PageParamsI, filter: Map<string, string>): Observable<Page<UserDetails>> {
    const url = `${this.environmentHelper.urlV2}/userDetails`;
    const params: HttpParams = HttpParamsUtil.createByPageAndFilter(pageParams, filter);
    return this.http.get<Page<UserDetails>>(url, {params: params})
      .pipe(
        map(value => {
          value.urlParams = params;
          return UserDetails.pageableValueOf(value);
        })
      );
  }

  public getChildren(pageIndex: number, pageSize: number, userId: string): Observable<Page<UserDetails>> {
    const url = `${this.environmentHelper.urlV2}/userDetails`;

    let params = new HttpParams();
    params = params.set(PageParam.PAGE, String(pageIndex));
    params = params.set(PageParam.SIZE, String(pageSize));
    params = params.set('userId', userId);

    return this.http.get<Page<UserDetails>>(url, {params: params}).pipe(
      map(page => UserDetails.pageableValueOf(page))
    );
  }

  public getById(userId: number): Observable<UserDetails> {
    const url = `${this.environmentHelper.urlV2}/userDetails/${userId}`;
    return this.http.get<UserDetails>(url).pipe(
      map(obj => UserDetails.valueOf(obj))
    );
  }

  public getAuthUser(): Observable<UserDetails> {
    const url = `${this.environmentHelper.urlV2}/userDetails/auth`;
    return this.http.get<UserDetails>(url).pipe(
      map(obj => UserDetails.valueOf(obj))
    );
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any

export const environment = {
  production: false,
  GUI_VERSION: require('../../../../package.json').version,
  BASE_URL: {
    BASE: 'http://localhost:8080',
    AURORA: '/aurora/api/v1',
    AURORA_V2: '/aurora/api/v2',
    DVR: '/bi-dvr/api/v1',
  },
  OUTAGE: {
    BASE_URL: 'https://outage.aurora-soft.xyz/serverOutageTracker/api/v1',
    SERVER_NAME: 'dev'
  },
  TOOLBAR_RESTRICTIONS: {
    DISABLE_CHART: [],
    DISABLE_TRACK: [],
    DISABLE_REPORT: []
  },
  LOGISTIC_RESTRICTION: {
    AVAILABLE_USER_IDS: []
  },
  TELEGRAM_URL: 'http://t.me/rua_test2_bot',
  HEADER: 'LOCAL',
  DEMO_LOGIN: 'admin',
  DEMO_PASSWORD: 'rootroot',
  DEFAULT_LANGUAGE: 'en',
  APP_ANDROID_URL: 'https://play.google.com/store/apps/details?id=by.aurorasoft.androidclient',
  SEARCH_ADDRESS_URL: 'https://nominatim.openstreetmap.org',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/**
 *
 * Use in mat-select [compareWith]
 *
 * import { comparisonId as comparisonIdUtility } from 'path-to-utils';
 *
 * export class ExampleComponent {
 *
 *   public comparisonId = comparisonIdUtility; // Assigning reference to the utility function
 * }
 * @param option
 * @param value
 */
export function comparisonId(option: any, value: any): boolean {
  return option?.id === value?.id;
}

import {Component, ContentChild, Input} from '@angular/core';
import {DialogActionsDirective} from "./dialog-actions.directive";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Input() title = 'Title Example';
  @Input() showDialogActions = true;
  @Input() actionsAlign: 'start' | 'end' | 'center' = 'center'
  @Input() showSpinner = false;

  @Input() closeIcon = 'clear';
  @Input() closeTooltip: string;
  @Input() closeTooltipShowDelay = 500;

  @ContentChild(DialogActionsDirective) dialogActions: DialogActionsDirective;
}

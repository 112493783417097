import {Component, OnInit} from '@angular/core';
import {ViewBreakpointService, ViewSize} from "../../app-services/view-breakpoint.service";
import {AppCustomizationService} from "../../app-services/app-customization.service";
import {Customization} from "../../modelinterfaces/customization.model";
import {EnvironmentHelper} from "../../services/environment-helper";
import {
  BaseUnsubscribeComponent
} from "../../../../../user-portal/src/app/shared/components/base-unsubscribe.component";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-logo-title',
  templateUrl: './logo-title.component.html',
  styleUrl: './logo-title.component.scss'
})
export class LogoTitleComponent extends BaseUnsubscribeComponent implements OnInit {

  size: ViewSize;
  customization: Customization;
  badge: string;

  constructor(private viewBreakpointService: ViewBreakpointService,
              private appCustomization: AppCustomizationService,
              private envHelper: EnvironmentHelper) {
    super();
  }

  ngOnInit(): void {
    this.viewBreakpointService.size$.pipe(takeUntil(this.destroy))
      .subscribe(size => this.size = size);
    this.appCustomization.customization$.pipe(takeUntil(this.destroy))
      .subscribe(customization => this.customization = customization);
    this.badge = this.envHelper.header;
  }

}
